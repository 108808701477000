import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable, asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional } from "@silvester/react-web-tables";
import { TableRowActionEdit_Props_External } from "@silvester/react-web-tables/dist/parts/actions/TableRowActionEdit";
import FormEditMedicineType from "../forms/FormEditMedicineType";
import FormAddMedicineType from "../forms/FormAddMedicineType";
import { withFormApi, WithFormApi_Props_Injected } from "@silvester/react-web-forms";

const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_NAME = "name";

const FORM_API_PATH = "/entities/medicine_type/table";
const TABLE_API_PATH = "/entities/medicine_type/table";

const styles = (theme: Theme) =>
({
    root:
    {

    }
})  

interface OwnProps
{

}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableMedicineTypes_Row> &
    WithFormApi_Props_Injected;

export interface TableMedicineTypes_Row
{
    [FIELD_ID]: number,
    [FIELD_NAME]: string,
}

const TableMedicineTypes = (props: Props) =>
{
    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Geneesmiddelvormen");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.addActionAdd(props.getActionAdd("Geneesmiddelvorm Toevoegen", "Toevoegen", { type: FormAddMedicineType, props: { } }))
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_NAME, "Naam", 11)
            .addAction("edit");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row =>
        {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_NAME])
                .addActionEdit(props.getActionEdit(row, "Geneesmiddelvorm Aanpassen", { type: FormEditMedicineType, props: { user: props.user, formApi: props.getFormApi(FORM_API_PATH), id: row[FIELD_ID].toString() } }))
                .addActionDelete(props.getActionDelete("Verwijder Geneesmiddelvorm", "Weet u zeker dat u deze geneesmiddelvorm wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt."));
        });

        return builder;
    }

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}

const hoc1 = asManagedTable<TableMedicineTypes_Row, Props>(TableMedicineTypes, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);

export default hoc3