import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable, asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional } from "@silvester/react-web-tables";
import { TableRowActionEdit_Props_External, ChildForm_Edit } from "@silvester/react-web-tables/dist/parts/actions/TableRowActionEdit";
import { Omit } from "@material-ui/core";
import { AbstractForm_Data, withFormApi, WithFormApi_Props_Injected, ChildForm } from "@silvester/react-web-forms";
import FormEditProtocol from "../forms/FormEditProtocol";
import FormAddProtocol from "../forms/FormAddProtocol";
import ReduxConfigurationEntity, { ReduxConfigurationEndpoints } from "../../../../../redux/entities/ReduxConfigurationEntity";
import { ChildSpecification } from "@silvester/utility-child-specification";
import { User } from "@silvester/utility-auth";
import { WithCallback } from "@silvester/react-web-forms/dist/components/forms/instances/WithForm";
import ReduxState from "../../../../../redux/ReduxState";
import { connect } from "react-redux";


const mapStateToProps = (state: ReduxState) => ({
    user: state.user,
    endpoints: state.configuration!.endpoints
});


const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_TITLE = "title";
const FIELD_DESCRIPTION = "description";
const FIELD_URL = "url";

const FORM_API_PATH = "/entities/protocol/table";
const TABLE_API_PATH = "/entities/protocol/table";

const styles = (theme: Theme) =>
    ({
        root:
        {

        }
    })

type OwnProps =
{
    endpoints: ReduxConfigurationEndpoints,
    user: User | undefined
}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableProtocols_Row> &
    WithFormApi_Props_Injected;

export interface TableProtocols_Row
{
    [FIELD_ID]: number,
    [FIELD_TITLE]: string,
    [FIELD_DESCRIPTION]: string,
    [FIELD_URL]: string
}



const TableProtocols = (props: Props) => {

    const getTableBuilder = () => {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Protocollen");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.addActionAdd(props.getActionAdd("Protocol Toevoegen", "Toevoegen", { type: FormAddProtocol, props: { } }))
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_TITLE, "Titel", 2)
            .addColumn(FIELD_URL, "Url", 3)
            .addColumn(FIELD_DESCRIPTION, "Omschrijving", 6)
            .addAction("edit")
            .addAction("delete");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_TITLE])
                .addTextual(row[FIELD_URL])
                .addTextual(row[FIELD_DESCRIPTION])
                .addActionEdit(props.getActionEdit(row, "Protocol Aanpassen", { type: FormEditProtocol, props: { user: props.user, id: row[FIELD_ID].toString(), formApi: props.getFormApi(FORM_API_PATH)} }))
                .addActionDelete(props.getActionDelete("Verwijderen", "Weet u zeker dat u dit protocol wilt verwijderen? Dit kan niet ongedaan worden gemaakt!"));
        });


        return builder;
    }

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}

const hoc1 = asManagedTable<TableProtocols_Row, Props>(TableProtocols, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);
const hoc4 = connect(mapStateToProps)(hoc3);

export default hoc4;