import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, RemoteSelectInformation, withManagedSelects, WithManagedSelects_Props_Injected} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_INDICATION_ID = "indicationId";
const FIELD_INITIAL_DOSAGE = "initialDosage";
const FIELD_DOSAGE_RANGE_LOWER = "dosageRangeLower";
const FIELD_DOSAGE_RANGE_UPPER = "dosageRangeUpper";
const FIELD_WEIGHT_RANGE_LOWER = "weightRangeLower";
const FIELD_WEIGHT_RANGE_UPPER = "weightRangeUpper";
const FIELD_PER_METRIC_ID = "perMetricId";
const FIELD_DOSAGE_METRIC_ID = "dosageMetricId";
const FIELD_PER_AMOUNT = "perAmount";

interface Data
{
    [FIELD_INDICATION_ID]: number,
    [FIELD_INITIAL_DOSAGE]: string,
    [FIELD_DOSAGE_RANGE_LOWER]: string,
    [FIELD_DOSAGE_RANGE_UPPER]: string,
    [FIELD_WEIGHT_RANGE_LOWER]: string,
    [FIELD_WEIGHT_RANGE_UPPER]: string,
    [FIELD_PER_METRIC_ID]: number,
    [FIELD_DOSAGE_METRIC_ID]: number,
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    indicationId: string
}

export interface FormAddMedicineIndicationDosageCalculation_Selects
{
    [FIELD_DOSAGE_METRIC_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormAddMedicineIndicationDosageCalculation_Selects>;

interface State
{
    
}

class FormAddMedicineIndicationDosageCalculation extends React.Component<Props, State>
{
    private getFormBuilder = () =>
    {
        const SIZE_FULL: GridSize = { xs: 12 };
        const SIZE_HALF: GridSize = { xs: 12, sm: 6};

        const builder = new AbstractFormBuilder();
        builder
            .addHidden({ id: FIELD_INDICATION_ID, input: this.props.indicationId })
            .addHidden({ id: FIELD_PER_METRIC_ID, input: 5 }) //5 is the Kilogram metric id.
            .addHidden({ id: FIELD_PER_AMOUNT, input: 1 }) //Always use per 1 kilogram.
            .addGroup()
            .addRow()
            .addNumerical(SIZE_FULL, { id: FIELD_INITIAL_DOSAGE, label: "Initiële Dosering", decimals: 4 })
            .addRow()
            .addNumerical({ xs: 12, sm: 4 }, { id: FIELD_DOSAGE_RANGE_LOWER, label: "Laagste Dosering", decimals: 4, validator: new AbstractValidatorBuilder().required().build() })
            .addNumerical({ xs: 12, sm: 4 }, { id: FIELD_DOSAGE_RANGE_UPPER, label: "Hoogste Dosering", decimals: 4, validator: new AbstractValidatorBuilder().required().build() })
            .addSelect({ xs: 12, sm: 4 }, { id: FIELD_DOSAGE_METRIC_ID, label: "Metriek", options: this.props.getSelectOptions(FIELD_DOSAGE_METRIC_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })
            .addRow()
            .addNumerical({ xs: 12, sm: 4 }, { id: FIELD_WEIGHT_RANGE_LOWER, label: "Laagste Gewicht", decimals: 4, validator: new AbstractValidatorBuilder().required().build() })
            .addNumerical({ xs: 12, sm: 4 }, { id: FIELD_WEIGHT_RANGE_UPPER, label: "Hoogste Gewicht", decimals: 4, validator: new AbstractValidatorBuilder().required().build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormAddMedicineIndicationDosageCalculation);
const hoc2 = withManagedSelects<FormAddMedicineIndicationDosageCalculation_Selects, OwnProps & WithManagedSelects_Props_Injected<FormAddMedicineIndicationDosageCalculation_Selects>>(hoc1);

export default hoc2; 