import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, RemoteSelectInformation, WithInitialData_Props_Injected, withInitialData, withManagedSelects, WithManagedSelects_Props_Injected, WithManagedSelects_Props_Additional} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";
import { compose } from "redux";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_ID = "id";
const FIELD_ACTIVE_SUBSTANCE_ID = "activeSubstanceId";
const FIELD_ACTIVE_SUBSTANCE_AMOUNT = "activeSubstanceAmount";
const FIELD_ACTIVE_SUBSTANCE_METRIC_ID = "activeSubstanceMetricId";
const FIELD_VOLUME_AMOUNT = "volumeAmount";
const FIELD_VOLUME_METRIC_ID = "volumeMetricId";
const FIELD_MEDICINE_ID = "medicineId";

interface Data
{
    [FIELD_ID]: number,
    [FIELD_ACTIVE_SUBSTANCE_ID]: number,
    [FIELD_ACTIVE_SUBSTANCE_AMOUNT]: number,
    [FIELD_ACTIVE_SUBSTANCE_METRIC_ID]: number,
    [FIELD_MEDICINE_ID]: number,
    [FIELD_VOLUME_AMOUNT]: number,
    [FIELD_VOLUME_METRIC_ID]: number
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    medicineId: string
}

export interface FormEditMedicineSubstance_Selects
{
    [FIELD_ACTIVE_SUBSTANCE_ID]: RemoteSelectInformation,
    [FIELD_ACTIVE_SUBSTANCE_METRIC_ID]: RemoteSelectInformation,
    [FIELD_VOLUME_METRIC_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormEditMedicineSubstance_Selects> &
    WithInitialData_Props_Injected<Data>;

interface State
{
}

class FormEditMedicineSubstance extends React.Component<Props, State>
{
    private getFormBuilder = (data: Partial<Data>) =>
    {
        const SIZE: GridSize = { xs: 12, sm: 3 };

        const builder = new AbstractFormBuilder();
        builder.setIsLoading(this.props.isLoading);
        builder
            .addHidden({ id: FIELD_MEDICINE_ID, input: this.props.medicineId })
            .addHidden({ id: FIELD_ID, input: data[FIELD_ID] })
            .addHidden({ id: FIELD_ACTIVE_SUBSTANCE_ID, input: data[FIELD_ACTIVE_SUBSTANCE_ID] })
            .addGroup()
            .addRow()
            .addNumerical(SIZE, { id: FIELD_ACTIVE_SUBSTANCE_AMOUNT, input: data[FIELD_ACTIVE_SUBSTANCE_AMOUNT], label: "Hoeveelheid", decimals: 4})
            .addSelect(SIZE, { id: FIELD_ACTIVE_SUBSTANCE_METRIC_ID, input: data[FIELD_ACTIVE_SUBSTANCE_METRIC_ID], label: "Metriek", options: this.props.getSelectOptions(FIELD_ACTIVE_SUBSTANCE_METRIC_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })
            .addNumerical(SIZE, { id: FIELD_VOLUME_AMOUNT, input: data[FIELD_VOLUME_AMOUNT], label: "Per Hoeveelheid", decimals: 4})
            .addSelect(SIZE, { id: FIELD_VOLUME_METRIC_ID, input: data[FIELD_VOLUME_METRIC_ID], label: "Per Metriek", options: this.props.getSelectOptions(FIELD_VOLUME_METRIC_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder(this.props.initialData || {}).build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormEditMedicineSubstance);
const hoc2 = withManagedSelects<FormEditMedicineSubstance_Selects, Props>(hoc1);
const hoc3 = withInitialData<Data, OwnProps & WithInitialData_Props_Injected<Data> & WithManagedSelects_Props_Additional<FormEditMedicineSubstance_Selects>>(hoc2);

export default hoc3;