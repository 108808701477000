import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable, asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional} from "@silvester/react-web-tables";
import { TableRowActionEdit_Props_External, ChildForm_Edit } from "@silvester/react-web-tables/dist/parts/actions/TableRowActionEdit";
import { Omit } from "@material-ui/core";
import { AbstractForm_Data, RemoteSelectApi, withFormApi, WithFormApi_Props_Injected  } from "@silvester/react-web-forms";
import ReduxConfigurationEntity, { ReduxConfigurationEndpoints } from "../../../../../redux/entities/ReduxConfigurationEntity";
import { ChildSpecification } from "@silvester/utility-child-specification";
import { User } from "@silvester/utility-auth";
import FormAddMedicine, { FormAddMedicine_Selects } from "../forms/FormAddMedicine";
import EditIcon from '@material-ui/icons/Edit';
import { withRouter, RouterProps, RouteComponentProps } from "react-router";
import { PATH_MEDICINE_EDIT } from "../../../../pages/home/PageHome";
import ReduxState from "../../../../../redux/ReduxState";
import { connect } from "react-redux";


const mapStateToProps = (state: ReduxState) => ({
    user: state.user,
    endpoints: state.configuration!.endpoints
});


const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_BRAND = "brand";
const FIELD_ATC_VET_CODE = "atcVetCode";
const FIELD_CHANNEL = "channel";
const FIELD_MEDICINE_TYPE = "type";
const FIELD_WAITING_TIME = "waitingTime";

const FORM_API_PATH = "/entities/medicine/table";
const TABLE_API_PATH = "/entities/medicine/table";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

type OwnProps =
{
    endpoints: ReduxConfigurationEndpoints,
    user: User | undefined
}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableMedicines_Row> &
    WithFormApi_Props_Injected &
    RouteComponentProps;

export interface TableMedicines_Row
{
    [FIELD_ID]: number,
    [FIELD_BRAND]: string,
    [FIELD_ATC_VET_CODE]: string,
    [FIELD_CHANNEL]: string,
    [FIELD_MEDICINE_TYPE]: string,
    [FIELD_WAITING_TIME]: string,
}

const TableAdministrations = (props: Props) =>
{
    const selectApi = new Map<keyof FormAddMedicine_Selects, RemoteSelectApi>();
    selectApi.set("channelId", new RemoteSelectApi(props.endpoints.formulariumAdminApi, "/entities/channel/select"));
    selectApi.set("medicineTypeId", new RemoteSelectApi(props.endpoints.formulariumAdminApi, "/entities/medicine_type/select"));

    const onEditClick = (id: string) =>
    {
        props.history.push(PATH_MEDICINE_EDIT + "?id=" + id);
    }

    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Medicijnen");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.addActionAdd(props.getActionAdd("Medicijn Toevoegen", "Toevoegen", { type: FormAddMedicine, props: { selectApi: selectApi, user: props.user } }))
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_BRAND, "Merk", 2)
            .addColumn(FIELD_ATC_VET_CODE, "ATC Vet Code", 3)
            .addColumn(FIELD_CHANNEL, "Kanalisatie", 1)
            .addColumn(FIELD_MEDICINE_TYPE, "Type", 2)
            .addColumn(FIELD_WAITING_TIME, "Wachttijd - Vlees", 3)
            .addAction("edit")
            .addAction("delete");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_BRAND])
                .addTextual(row[FIELD_ATC_VET_CODE])
                .addTextual(row[FIELD_CHANNEL])
                .addTextual(row[FIELD_MEDICINE_TYPE])
                .addTextual(row[FIELD_WAITING_TIME])
                .addActionIcon(new ChildSpecification(EditIcon, {}), onEditClick, "Aanpassen")
                .addActionDelete(props.getActionDelete("Verwijderen", "Weet u zeker dat u dit medicijn wilt verwijderen? Dit kan niet ongedaan worden gemaakt!"));
        });
        

        return builder;
    }

    return (
        <div>
            <AbstractTable model={getTableBuilder().build()} />
        </div>
    );
}

const hoc1 = asManagedTable<TableMedicines_Row, Props>(TableAdministrations, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional & RouteComponentProps>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);
const hoc4 = withRouter(hoc3);
const hoc5 = connect(mapStateToProps)(hoc4);

export default hoc5;