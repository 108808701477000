import { AbstractApi, User } from "@silvester/utility-auth";
import { ReduxConfigurationEndpoints } from "../../../redux/entities/ReduxConfigurationEntity";
import { AbstractForm_Data } from "@silvester/react-web-forms";

export default class ExportWordApi extends AbstractApi
{
    constructor(endpoints: ReduxConfigurationEndpoints)
    {
        super(endpoints.formulariumAdminApi + "/exports/word")
    }

    public sendExportRequest = async (user: User | undefined, data: AbstractForm_Data) =>
    {
        return await this.sendGetRequest("/start", data, user); 
    }
} 