import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, withManagedSelects, WithManagedSelects_Props_Injected, RemoteSelectInformation} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_NAME = "name";
const FIELD_CATEGORY_ID = "administrationCategoryId";

interface Data
{
    [FIELD_NAME]: string,
    [FIELD_CATEGORY_ID]: number
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
}

export interface FormAddAdministration_Selects
{
    [FIELD_CATEGORY_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormAddAdministration_Selects>;

interface State
{
    
}

class FormAddAdministration extends React.Component<Props, State>
{
   
    private getFormBuilder = () =>
    {
        const SIZE_FULL: GridSize = {sm: 12};

        const builder = new AbstractFormBuilder();
        builder
            .addGroup()
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_NAME, label: "Naam", validator: new AbstractValidatorBuilder().required().minimum(2).maximum(32).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addSelect(SIZE_FULL, { id: FIELD_CATEGORY_ID, label: "Categorie", options: this.props.getSelectOptions(FIELD_CATEGORY_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormAddAdministration);
const hoc2 = withManagedSelects<FormAddAdministration_Selects, OwnProps & WithManagedSelects_Props_Injected<FormAddAdministration_Selects>>(hoc1);

export default hoc2; 