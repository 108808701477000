import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable, asManagedTable, AsManagedTable_Props_Injected } from "@silvester/react-web-tables";

const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_NAME = "name";
const FIELD_ABBREVATION = "abbreviation";

const TABLE_API_PATH = "/entities/metric/table";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

interface OwnProps 
{

}

type Props = OwnProps & AsManagedTable_Props_Injected<TableMetrics_Row>;

export interface TableMetrics_Row
{
    [FIELD_ID]: number,
    [FIELD_NAME]: string,
    [FIELD_ABBREVATION]: string
}
 
const TableMetrics = (props: Props) =>
{
    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Metrieken");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_NAME, "Naam", 5)
            .addColumn(FIELD_ABBREVATION, "Afkorting", 5);

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_NAME])
                .addTextual(row[FIELD_ABBREVATION]);
        });

        return builder;
    }

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}

const hoc1 = asManagedTable<TableMetrics_Row, OwnProps & AsManagedTable_Props_Injected<TableMetrics_Row>>(TableMetrics, TABLE_API_PATH);

export default injectSheet<string, Theme, OwnProps>(styles)(hoc1);