import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable } from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, FormInputSelect_Option } from "@silvester/react-web-forms";
import { AbstractValidatorBuilder } from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) =>
    ({
        root:
        {

        }
    })

const FIELD_TITLE = "title";
const FIELD_URL = "url";
const FIELD_DESCRIPTION = "description";


interface OwnProps {
    onFieldChange: FormValueChangedCallback,
}

type Props = OwnProps;

interface State {

}

class FormAddProtocol extends React.Component<Props, State>
{

    private getFormBuilder = () => {
        const SIZE_FULL: GridSize = { sm: 12 };

        const builder = new AbstractFormBuilder();
        builder
            .addGroup()
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_TITLE, label: "Titel", validator: new AbstractValidatorBuilder().required().minimum(2).maximum(256).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_DESCRIPTION, label: "Omschrijving", multiline: true, numberOfLines: 3, validator: new AbstractValidatorBuilder().required().minimum(2).maximum(256).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_URL, label: "Url", validator: new AbstractValidatorBuilder().required().url().build() })

        return builder;
    }

    render() {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormAddProtocol);

export default hoc1; 