import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable } from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, FormInputSelect_Option } from "@silvester/react-web-forms";
import { AbstractValidatorBuilder } from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) =>
    ({
        root:
        {

        }
    })

const FIELD_TITLE = "title";
const FIELD_URL = "url";
const FIELD_DESCRIPTION = "description";
const FIELD_MEDICINE_ID = "medicineId";

interface OwnProps {
    onFieldChange: FormValueChangedCallback,
    medicineId: string
}

type Props = OwnProps;

interface State {

}

class FormAddMedicineLink extends React.Component<Props, State>
{

    private getFormBuilder = () => {
        const builder = new AbstractFormBuilder();
        builder
            .addHidden({ id: FIELD_MEDICINE_ID, input: this.props.medicineId })
            .addGroup()
            .addRow()
            .addText({ xs: 12 }, { id: FIELD_TITLE, label: "Titel", validator: new AbstractValidatorBuilder().legal(DEFAULT_LEGAL_INPUT_CHARACTERS).maximum(256).required().build() })
            .addText({ xs: 12 }, { id: FIELD_DESCRIPTION, label: "Omschrijving", multiline: true, numberOfLines: 3, validator: new AbstractValidatorBuilder().maximum(256).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).required().build() })
            .addText({ xs: 12 }, { id: FIELD_URL, label: "Url", validator: new AbstractValidatorBuilder().url().legal(DEFAULT_LEGAL_INPUT_CHARACTERS).maximum(256).required().build() })

        return builder;
    }

    render() {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormAddMedicineLink);
export default hoc1; 