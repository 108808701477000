import React from "react";
import { AbstractActionBarBuilder, AbstractActionBar } from "@silvester/react-web-action-bars";
import { AbstractListBuilder } from "@silvester/react-web-lists";
import { AbstractDrawerBuilder, AbstractDrawer } from "@silvester/react-web-drawers";
import { withRouter, Route } from "react-router-dom";
import { History } from "history";
import { Theme } from "@material-ui/core";
import injectSheet from "react-jss";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import ReduxState from "../../../../redux/ReduxState";
import ReduxConfigurationEntity from "../../../../redux/entities/ReduxConfigurationEntity";
import { User } from "@silvester/utility-auth";
import TableProtocols, { TableProtocols_Row } from "../../../components/entities/protocols/tables/TableProtocols";

const mapStateToProps = (state: ReduxState) =>
    ({
        user: state.user,
        configuration: state.configuration!
    });

const styles = (theme: Theme) =>
    ({
        root:
        {
            padding: 20
        }
    });

export interface OwnProps {

}

interface ReduxProps {
    classes: ClassNameMap,
    user?: User,
    configuration: ReduxConfigurationEntity
}

type Props = OwnProps & ReduxProps;

interface State {

}

class ContentProtocols extends React.Component<Props, State>
{
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <TableProtocols endpoint={this.props.configuration.endpoints.formulariumAdminApi} rowsPerPage={10} />
            </div>
        );
    }
}

const hoc1 = connect(mapStateToProps)(ContentProtocols);
export default injectSheet<string, Theme, OwnProps>(styles)(hoc1);
