import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, WithInitialData_Props_Injected, withInitialData, withManagedSelects, WithManagedSelects_Props_Injected, WithManagedSelects_Props_Additional, RemoteSelectInformation} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";
import { compose } from "redux";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_ID = "id";
const FIELD_AMOUNT = "amount";
const FIELD_METRIC_ID = "metricId";
const FIELD_MEDICINE_ID = "medicineId";

interface Data
{
    [FIELD_ID]: number,
    [FIELD_AMOUNT]: number,
    [FIELD_METRIC_ID]: number,
    [FIELD_MEDICINE_ID]: number
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    medicineId: string
}

export interface FormEditMedicinePackage_Selects
{
    [FIELD_METRIC_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormEditMedicinePackage_Selects> &
    WithInitialData_Props_Injected<Data>;

interface State
{
}

class FormEditMedicinePackage extends React.Component<Props, State>
{
    private getFormBuilder = (data: Partial<Data>) =>
    {
        const builder = new AbstractFormBuilder();
        builder.setIsLoading(this.props.isLoading);
        builder
            .addHidden({ id: FIELD_MEDICINE_ID, input: this.props.medicineId })
            .addHidden({ id: FIELD_ID, input: data[FIELD_ID] })
            .addGroup()
            .addRow()
            .addNumerical({ xs: 12, sm: 9 }, { id: FIELD_AMOUNT, input: data[FIELD_AMOUNT], label: "Hoeveelheid", validator: new AbstractValidatorBuilder().required().build() })
            .addSelect({ xs: 12, sm: 3 }, { id: FIELD_METRIC_ID, input: data[FIELD_METRIC_ID], label: "Metriek", options: this.props.getSelectOptions(FIELD_METRIC_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder(this.props.initialData || {}).build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormEditMedicinePackage);
const hoc2 = withManagedSelects<FormEditMedicinePackage_Selects, Props>(hoc1);
const hoc3 = withInitialData<Data, OwnProps & WithInitialData_Props_Injected<Data> & WithManagedSelects_Props_Additional<FormEditMedicinePackage_Selects>>(hoc2);

export default hoc3;