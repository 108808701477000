import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { withManagedSelects, WithManagedSelects_Props_Injected, WithManagedSelects_Props_Additional, RemoteSelectInformation, WithInitialData_Props_Injected, withInitialData, AbstractForm, AbstractFormBuilder, FormValueChangedCallback, FormInputSelect_Option} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";
import { compose } from "redux";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_ID = "id";
const FIELD_INDICATION_ID = "indicationId";
const FIELD_INITIAL_DOSAGE = "initialDosage";
const FIELD_DOSAGE_RANGE_LOWER = "dosageRangeLower";
const FIELD_DOSAGE_RANGE_UPPER = "dosageRangeUpper";
const FIELD_WEIGHT_RANGE_LOWER = "weightRangeLower";
const FIELD_WEIGHT_RANGE_UPPER = "weightRangeUpper";
const FIELD_PER_METRIC_ID = "perMetricId";
const FIELD_DOSAGE_METRIC_ID = "dosageMetricId";
const FIELD_PER_AMOUNT = "perAmount";

interface Data
{
    [FIELD_ID]: number,
    [FIELD_INDICATION_ID]: number,
    [FIELD_INITIAL_DOSAGE]: string,
    [FIELD_DOSAGE_RANGE_LOWER]: string,
    [FIELD_DOSAGE_RANGE_UPPER]: string,
    [FIELD_WEIGHT_RANGE_LOWER]: string,
    [FIELD_WEIGHT_RANGE_UPPER]: string,
    [FIELD_PER_AMOUNT]: number,
    [FIELD_PER_METRIC_ID]: number,
    [FIELD_DOSAGE_METRIC_ID]: number,
}


interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    indicationId: string
}


interface FormEditMedicineIndicationDosageCalculation_Selects
{
    [FIELD_DOSAGE_METRIC_ID]: RemoteSelectInformation
}


type Props = OwnProps  &
    WithInitialData_Props_Injected<Data> &
    WithManagedSelects_Props_Injected<FormEditMedicineIndicationDosageCalculation_Selects>;

interface State
{
}

class FormEditMedicineIndicationDosageCalculation extends React.Component<Props, State>
{
    private getFormBuilder = (data: Partial<Data>) =>
    {
        const SIZE_FULL: GridSize = { xs: 12 };

        const builder = new AbstractFormBuilder();
        builder.setIsLoading(this.props.isLoading);
        builder
            .addHidden({id: FIELD_ID, input: data[FIELD_ID]})
            .addHidden({ id: FIELD_INDICATION_ID, input: this.props.indicationId })
            .addHidden({ id: FIELD_PER_METRIC_ID, input: data[FIELD_PER_METRIC_ID] })
            .addHidden({ id: FIELD_PER_AMOUNT, input: data[FIELD_PER_AMOUNT] })
            .addGroup()
            .addRow()
            .addNumerical(SIZE_FULL, { id: FIELD_INITIAL_DOSAGE, input: data[FIELD_INITIAL_DOSAGE], label: "Initiële Dosering", decimals: 4 })
            .addRow()
            .addNumerical({ xs: 12, sm: 4 }, { id: FIELD_DOSAGE_RANGE_LOWER, input: data[FIELD_DOSAGE_RANGE_LOWER], label: "Laagste Dosering", decimals: 4, validator: new AbstractValidatorBuilder().required().build() })
            .addNumerical({ xs: 12, sm: 4 }, { id: FIELD_DOSAGE_RANGE_UPPER, input: data[FIELD_DOSAGE_RANGE_UPPER], label: "Hoogste Dosering", decimals: 4, validator: new AbstractValidatorBuilder().required().build() })
            .addSelect({ xs: 12, sm: 4 }, { id: FIELD_DOSAGE_METRIC_ID, input: data[FIELD_DOSAGE_METRIC_ID], label: "Metriek", options: this.props.getSelectOptions(FIELD_DOSAGE_METRIC_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })
            .addRow()
            .addNumerical({ xs: 12, sm: 4 }, { id: FIELD_WEIGHT_RANGE_LOWER, input: data[FIELD_WEIGHT_RANGE_LOWER], label: "Laagste Gewicht", decimals: 0, validator: new AbstractValidatorBuilder().required().build() })
            .addNumerical({ xs: 12, sm: 4 }, { id: FIELD_WEIGHT_RANGE_UPPER, input: data[FIELD_WEIGHT_RANGE_UPPER], label: "Hoogste Gewicht", decimals: 0, validator: new AbstractValidatorBuilder().required().build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder(this.props.initialData || {}).build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormEditMedicineIndicationDosageCalculation);
const hoc2 = withManagedSelects<FormEditMedicineIndicationDosageCalculation_Selects, Props>(hoc1);
const hoc3 = withInitialData<Data, OwnProps & WithInitialData_Props_Injected<Data> & WithManagedSelects_Props_Additional<FormEditMedicineIndicationDosageCalculation_Selects>>(hoc2);

export default hoc3;