import { Action } from 'redux';
import ActionAuthInitialized from "../actions/ActionAuthInitialized";
import ActionUserInitialized from "../actions/ActionUserInitialized";
import ActionConfigurationLoaded from "../actions/ActionConfigurationLoaded";
import UtilityRedux from "../../utilities/UtilityRedux";
import ReduxState from '../ReduxState';

const InitialReducer = (state = new ReduxState(), action: Action): ReduxState =>
{
    if (UtilityRedux.actionIs<ActionAuthInitialized>(action, ActionAuthInitialized.TYPE))
    {
        return { ...state, userManager: action.userManager };
    }

    if (UtilityRedux.actionIs<ActionUserInitialized>(action, ActionUserInitialized.TYPE))
    {
        return { ...state, user: action.user || undefined }; 
    }

    if (UtilityRedux.actionIs<ActionConfigurationLoaded>(action, ActionConfigurationLoaded.TYPE))
    {
        return { ...state, configuration: action.configuration };
    }

    return state;
};

export default InitialReducer;