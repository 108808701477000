import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable, asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional} from "@silvester/react-web-tables";
import { TableRowActionEdit_Props_External, ChildForm_Edit } from "@silvester/react-web-tables/dist/parts/actions/TableRowActionEdit";
import { Omit } from "@material-ui/core";
import { withFormApi, WithFormApi_Props_Injected, RemoteFormApi, WithManagedSelects_Props_Additional, withManagedSelects, RemoteSelectApi } from "@silvester/react-web-forms";
import ReduxConfigurationEntity, { ReduxConfigurationEndpoints } from "../../../../../redux/entities/ReduxConfigurationEntity";
import { ChildSpecification } from "@silvester/utility-child-specification";
import { User } from "@silvester/utility-auth";
import { WithCallback } from "@silvester/react-web-forms/dist/components/forms/instances/WithForm";
import FormAddActiveSubstance, { FormAddActiveSubstance_Selects } from "../forms/FormAddActiveSubstance";
import FormEditActiveSubstance from "../forms/FormEditActiveSubstance";
import { WithSnackbar_Props_Injected } from "@silvester/hoc-with-snackbar";
import { WithResponseHandler_Props_Additional } from "@silvester/utility-auth";
import ReduxState from "../../../../../redux/ReduxState";
import { connect } from "react-redux";


const mapStateToProps = (state: ReduxState) => ({
    user: state.user,
    endpoints: state.configuration!.endpoints
});


const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_NAME = "name";
const FIELD_ADDENDUM = "addendum";
const FIELD_FEI = "fei";
const FIELD_DESCRIPTION = "description";

const FORM_API_PATH = "/entities/active_substance/table";
const TABLE_API_PATH = "/entities/active_substance/table";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

type OwnProps =
{
    endpoints: ReduxConfigurationEndpoints,
    user: User | undefined
}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableActiveSubstances_Row> &
    WithFormApi_Props_Injected;

export interface TableActiveSubstances_Row
{
    [FIELD_ID]: number,
    [FIELD_NAME]: string,
    [FIELD_ADDENDUM]: string,
    [FIELD_FEI]: string,
    [FIELD_DESCRIPTION]: string,
}

const TableActiveSubstances = (props: Props) =>
{
    const selectApi = new Map<keyof FormAddActiveSubstance_Selects, RemoteSelectApi>();
    selectApi.set("feiRegistrationId", new RemoteSelectApi(props.endpoints.formulariumAdminApi, "/entities/fei_registration/select"));

    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Actieve Stoffen");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.addActionAdd(props.getActionAdd("Actieve Stof Toevoegen", "Toevoegen", { type: FormAddActiveSubstance, props: { selectApi: selectApi, user: props.user } }))
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_NAME, "Naam", 3)
            .addColumn(FIELD_ADDENDUM, "Addendum", 2)
            .addColumn(FIELD_FEI, "FEI", 2)
            .addColumn(FIELD_DESCRIPTION, "Omschrijving", 5)
            .addAction("edit")
            .addAction("delete");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_NAME])
                .addTextual(row[FIELD_ADDENDUM])
                .addTextual(row[FIELD_FEI])
                .addTextual(row[FIELD_DESCRIPTION])
                .addActionEdit(props.getActionEdit(row, "Actieve Stof Aanpassen", { type: FormEditActiveSubstance, props: { user: props.user, id: row[FIELD_ID].toString(), formApi: props.getFormApi(FORM_API_PATH), selectApi: selectApi } }))
                .addActionDelete(props.getActionDelete("Verwijderen", "Weet u zeker dat u deze actieve stof wilt verwijderen? Dit kan niet ongedaan worden gemaakt!"));
        });
        

        return builder;
    }

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}
const hoc1 = asManagedTable<TableActiveSubstances_Row, Props>(TableActiveSubstances, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, {}>(styles)(hoc2);
const hoc4 = connect(mapStateToProps)(hoc3);

export default hoc4;