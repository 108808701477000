import React from "react";
import {AbstractActionBarBuilder, AbstractActionBar} from "@silvester/react-web-action-bars";
import {AbstractListBuilder} from "@silvester/react-web-lists";
import {AbstractDrawerBuilder, AbstractDrawer} from "@silvester/react-web-drawers";
import { withRouter, Route, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { Theme, Divider, Paper } from "@material-ui/core";
import injectSheet from "react-jss";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { User } from "@silvester/utility-auth";
import queryString from "query-string";
import { CardForm, ChildForm, AbstractForm_Data, WithCallback, FormValueChangedCallback } from "@silvester/react-web-forms";
import { ChildForm_Edit_Props } from "@silvester/react-web-tables";
import { ChildSpecification } from "@silvester/utility-child-specification";
import ReduxState from "../../../../../redux/ReduxState";
import ReduxConfigurationEntity from "../../../../../redux/entities/ReduxConfigurationEntity";
import { FormEditMedicine_Selects } from "../forms/FormEditMedicine";
import FormEditMedicine from "../forms/FormEditMedicine";
import { ModalMessage } from "@silvester/react-web-modals";
import { AbstractTabHostBuilder, AbstractTabHost } from "@silvester/react-web-tabs";
import TableMedicinePackages from "../../medicine_packages/tables/TableMedicinePackages";
import TableMedicineAdministration from "../../medicine_administrations/tables/TableMedicineAdministrations";
import TableMedicineCategories from "../../medicine_categories/tables/TableMedicineCategories";
import TableMedicineTags from "../../medicine_tags/tables/TableMedicineTags";
import TableMedicineSubstances from "../../medicine_substances/tables/TableMedicineSubstances";
import TableMedicineIndications from "../../medicine_indications/tables/TableMedicineIndications";
import TableMedicineLinks from "../../medicine_links/tables/TableMedicineLinks";
import TableMedicineProtocols from "../../medicine_protocols/tables/TableMedicineProtocols";
import TableMedicineIndicationDosageCalculations from "../../medicine_indication_dosage_calculations/tables/TableMedicineIndicationDosageCalculations";

const mapStateToProps = (state: ReduxState) => 
({
        user: state.user,
        configuration: state.configuration!
});

const styles = (theme: Theme) => 
({
    root: 
    {   
        padding: 20 
    }
});

export interface TabsMedicineEdit_Props_External 
{
    medicineId: string
}

interface TabsMedicineEdit_Props_Internal
{
    classes: ClassNameMap,
    user?: User,
    configuration: ReduxConfigurationEntity
}

type Props = TabsMedicineEdit_Props_External &
    TabsMedicineEdit_Props_Internal;

interface State
{
    selectedIndicationId?: string
}

class TabsMedicineEdit extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
    
        this.state =
            {

        }
    }

    private getTabHostBuilder = () =>
    {
        const sharedProps = { user: this.props.user, parentEntityId: this.props.medicineId, rowsPerPage: 5, endpoints: this.props.configuration.endpoints, endpoint: this.props.configuration.endpoints.formulariumAdminApi };
        const builder = new AbstractTabHostBuilder();
        builder.addTab(new ChildSpecification(TableMedicinePackages, sharedProps)).setLabel("Inhouden");
        builder.addTab(new ChildSpecification(TableMedicineLinks, sharedProps)).setLabel("Links");
        builder.addTab(new ChildSpecification(TableMedicineProtocols, sharedProps)).setLabel("Protocollen");
        builder.addTab(new ChildSpecification(TableMedicineAdministration, sharedProps)).setLabel("Toedieningen");
        builder.addTab(new ChildSpecification(TableMedicineCategories, sharedProps)).setLabel("Categorieën");
        builder.addTab(new ChildSpecification(TableMedicineTags, sharedProps)).setLabel("Tags");
        builder.addTab(new ChildSpecification(TableMedicineSubstances, sharedProps)).setLabel("Stoffen");
        builder.addTab(new ChildSpecification(this.getIndicationTabContent, {})).setLabel("Indicaties");
        return builder;
    }

    private getIndicationTabContent = () =>
    {
        return (
            <div>
                <TableMedicineIndications onIndicationSelected={this.onIndicationSelected} parentEntityId={this.props.medicineId} rowsPerPage={5} endpoint={this.props.configuration.endpoints.formulariumAdminApi}/>
                <div style={{ paddingTop: 20 }}></div>
                <TableMedicineIndicationDosageCalculations parentEntityId={this.state.selectedIndicationId} rowsPerPage={5} endpoint={this.props.configuration.endpoints.formulariumAdminApi} />
            </div>
        );
    }

    private onIndicationSelected = (rowId: string) =>
    {
        this.setState({ selectedIndicationId: rowId });
    }

	render()
	{
        const {classes} = this.props;

        return (
            <div>
                <AbstractTabHost model={this.getTabHostBuilder().build()} dividingContent={{ type: Divider, props: {} }} />
            </div>
        );
	}
}

const Empty = () => {
    return <div><p>Empty Content</p></div>
}

const hoc1 = connect(mapStateToProps)(TabsMedicineEdit);
export default injectSheet<string, Theme, TabsMedicineEdit_Props_External>(styles)(hoc1);
