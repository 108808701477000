import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, FormInputSelect_Option, WithInitialData_Props_Injected, withInitialData, withManagedSelects, WithManagedSelects_Props_Injected, WithManagedSelects_Props_Additional} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";
import { compose } from "redux";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_ID = "id";
const FIELD_MEDICINE_ID = "medicineId";
const FIELD_TITLE = "title";
const FIELD_DOSAGE_DESCRIPTION = "dosageDescription";
const FIELD_DESCRIPTION = "description";


interface Data
{
    [FIELD_ID]: string,
    [FIELD_MEDICINE_ID]: string,
    [FIELD_TITLE]: string,
    [FIELD_DOSAGE_DESCRIPTION]: string,
    [FIELD_DESCRIPTION]: string,
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    medicineId: string
}

type Props = OwnProps  &
    WithInitialData_Props_Injected<Data>;

interface State
{
}

class FormEditMedicineIndication extends React.Component<Props, State>
{
    private getFormBuilder = (data: Partial<Data>) =>
    {
        const SIZE_FULL: GridSize = { xs: 12 };

        const builder = new AbstractFormBuilder();
        builder.setIsLoading(this.props.isLoading);
        builder
            .addHidden({ id: FIELD_MEDICINE_ID, input: this.props.medicineId })
            .addHidden({ id: FIELD_ID, input: data[FIELD_ID] })
            .addGroup()
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_TITLE, label: "Titel", input: data[FIELD_TITLE], validator: new AbstractValidatorBuilder().required().minimum(2).maximum(64).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_DOSAGE_DESCRIPTION, label: "Dosering", input: data[FIELD_DOSAGE_DESCRIPTION], multiline: true, numberOfLines: 5, validator: new AbstractValidatorBuilder().maximum(256).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_DESCRIPTION, label: "Omschrijving", input: data[FIELD_DESCRIPTION], multiline: true, numberOfLines: 5, validator: new AbstractValidatorBuilder().required().minimum(5).maximum(1024).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder(this.props.initialData || {}).build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormEditMedicineIndication);
const hoc2 = withInitialData<Data, OwnProps & WithInitialData_Props_Injected<Data>>(hoc1);

export default hoc2;