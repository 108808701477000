import React from "react";
import {AbstractActionBarBuilder, AbstractActionBar, ActionIcon} from "@silvester/react-web-action-bars";
import {AbstractListBuilder} from "@silvester/react-web-lists";
import {AbstractDrawerBuilder, AbstractDrawer} from "@silvester/react-web-drawers";
import { withRouter, Route } from "react-router-dom";
import { History } from "history";

import ContentMetrics from "./content/ContentMetrics";
import ContentActiveSubstances from "./content/ContentActiveSubstances";
import ContentMedicine from "./content/ContentMedicine";
import ContentAdministrations from "./content/ContentAdministrations";
import ContentChannels from "./content/ContentChannels";
import { RouteComponentProps } from "react-router";
import ContentFeiRegistrations from "./content/ContentFeiRegistrations";
import ContentMedicineTypes from "./content/ContentMedicineTypes";
import ContentTags from "./content/ContentTags";
import ContentCategories from "./content/ContentCategories";
import ContentMedicineEdit from "./content/ContentMedicineEdit";
import ContentProtocols from "./content/ContentProtocols";
import ContentDocuments from "./content/ContentDocuments";
import ReduxState from "../../../redux/ReduxState";
import { UserManager, User, AbstractApi } from "@silvester/utility-auth";
import { connect } from "react-redux";
import { ModalForm } from "@silvester/react-web-forms";
import ModalChangePassword from "../../components/change_password/ModalChangePassword";
import DeveloperApi from "../../../utilities/developer/DeveloperApi";
import ReduxConfigurationEntity from "../../../redux/entities/ReduxConfigurationEntity";
import IconUkpLion from "../../icons/IconUkpLion";
import { ChildSpecification } from "@silvester/utility-child-specification";
import ModalExportWord from "../../components/export_word/ModalExportWord";
import ContentCoupons from "./content/ContentCoupons";

const ACTION_ID_NONE = "n.a.";
const ACTION_ID_CHANGE_PASSWORD = "change_password";
const ACTION_ID_LOGOUT = "logout";
const ACTION_ID_EXPORT_PDF = "export_pdf";
const ACTION_ID_GO_TO_DESKTOP_PORTAL = "go_to_desktop_portal";
export const PATH_MEDICINE_EDIT = "/medicine/edit";
const PATH_MEDICINES = "/medicine/overview";
const PATH_ACTIVE_SUBSTANCES = "/activesubstance/overview";
const PATH_CATEGORIES = "/categories/overview";
const PATH_TAGS = "/tags/overview";
const PATH_MEDICINE_TYPES = "/medicinetype/overview";
const PATH_ADMINISTRATION = "/administration/overview";
const PATH_METRICS = "/metrics/overview";
const PATH_CHANNELS = "/channels/overview";
const PATH_FEI_REGISTRATIONS = "/fei/overview";
const PATH_COUPONS = "/coupons/overview";
const PATH_PROTOCOLS = "/protocols/overview";
const PATH_DOCUMENTS = "/documents/overview";

const DRAWER_WIDTH = 300; 

const mapStateToProps = (state: ReduxState) =>
({
    userManager: state.userManager!,
    user: state.user!,
    configuration: state.configuration!
})

export interface OwnProps
{
    userManager: UserManager,
    user: User,
    configuration: ReduxConfigurationEntity
}

type Props = OwnProps & RouteComponentProps;

interface State
{
    selectedDrawerItemId: string,
    isDrawerMenuOpen: boolean,
    isChangePasswordModalOpen: boolean,
    isExportPdfModalOpen: boolean
}

class PageHome extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);

        var selectedDrawerItemId = this.props.location.pathname;
        if (selectedDrawerItemId == undefined || selectedDrawerItemId == "" || selectedDrawerItemId == "/")
        {
            selectedDrawerItemId = PATH_MEDICINES;
        }

	    this.state =
	    {
            selectedDrawerItemId: selectedDrawerItemId,
            isDrawerMenuOpen: true,
            isChangePasswordModalOpen: false,
            isExportPdfModalOpen: false
	    }
    }

    private setIsChangePasswordModalOpen = (isChangePasswordModalOpen: boolean) =>
    {
        this.setState({ isChangePasswordModalOpen: isChangePasswordModalOpen });
    }

	private getActionBarBuilder = () =>
	{
        const builder = new AbstractActionBarBuilder();
        builder.addPrimaryAction(ActionIcon, { title: "", id: "home", onClick: (id) => this.navigateToPath(PATH_MEDICINES), icon: new ChildSpecification(IconUkpLion, {}) });
        builder.addActionButton({ id: ACTION_ID_GO_TO_DESKTOP_PORTAL, title: "Desktop Portaal", onClick: this.onActionBarItemClick })
        return builder;
	}

	private getDrawerListBuilder = () =>
	{
		const builder = new AbstractListBuilder();
        builder.addStandardItem(ACTION_ID_NONE, "Dynamisch").setIsBold(true);
		builder.addDivider();
		builder.addStandardItem(PATH_MEDICINES, "Medicijnen").setIsButton(true);
		builder.addStandardItem(PATH_ACTIVE_SUBSTANCES, "Actieve Stoffen").setIsButton(true);
		builder.addStandardItem(PATH_CATEGORIES, "Categorieën").setIsButton(true);
		builder.addStandardItem(PATH_TAGS, "Tags").setIsButton(true);
		builder.addStandardItem(PATH_MEDICINE_TYPES, "Geneesmiddelvormen").setIsButton(true);
        builder.addStandardItem(PATH_ADMINISTRATION, "Toedieningsvormen").setIsButton(true);
        builder.addStandardItem(PATH_PROTOCOLS, "Protocollen").setIsButton(true);
        builder.addStandardItem(PATH_DOCUMENTS, "Documenten").setIsButton(true);
        builder.addStandardItem(ACTION_ID_NONE, "Statisch").setIsBold(true);
		builder.addDivider();
		builder.addStandardItem(PATH_METRICS, "Metrieken").setIsButton(true);
		builder.addStandardItem(PATH_CHANNELS, "Kanalisaties").setIsButton(true);
        builder.addStandardItem(PATH_FEI_REGISTRATIONS, "FEI Registraties").setIsButton(true);
        builder.addStandardItem(ACTION_ID_NONE, "Account").setIsBold(true);
		builder.addDivider();
        builder.addStandardItem(ACTION_ID_CHANGE_PASSWORD, "Wachtwoord Wijzigen").setIsButton(true);
        builder.addStandardItem(ACTION_ID_LOGOUT, "Uitloggen").setIsButton(true);

        if (this.props.user.hasRole("product_owner"))
        {
            builder.addStandardItem(ACTION_ID_NONE, "Exporteren").setIsBold(true);
            builder.addDivider();
            builder.addStandardItem(ACTION_ID_EXPORT_PDF, "Word Genereren").setIsButton(true);
        }

        if (this.props.user.hasRole("developer"))
        {
            builder.addStandardItem(ACTION_ID_NONE, "Developer").setIsBold(true);
            builder.addDivider();
            builder.addStandardItem(PATH_COUPONS, "Android Coupons").setIsButton(true);
        }
		
		return builder;
	}

	private getDrawerBuilder = () =>
	{
        const builder = new AbstractDrawerBuilder(this.getDrawerListBuilder());
        builder.addHeaderImage({ image: require("../../../images/material_design_header_purple_01.png"), alt: "Header Image" });

        return builder;
	}

    private setIsDrawerMenuOpen = (isDrawerMenuOpen: boolean) =>
    {
        this.setState({isDrawerMenuOpen: isDrawerMenuOpen});
    }

    private setSelectedDrawerItemId = (selectedDrawerItemId: string) =>
    {
        this.setState({selectedDrawerItemId: selectedDrawerItemId});
    }

    private onDrawerItemClick = async (id: string) =>
    {
        switch(id)
        {
            case PATH_MEDICINES:
            case PATH_ACTIVE_SUBSTANCES:
            case PATH_CATEGORIES:
            case PATH_TAGS:
            case PATH_MEDICINE_TYPES:
            case PATH_ADMINISTRATION:
            case PATH_METRICS:
            case PATH_CHANNELS:
            case PATH_FEI_REGISTRATIONS:
            case PATH_COUPONS:
            case PATH_PROTOCOLS:
            case PATH_DOCUMENTS:
                this.navigateToPath(id);
                break;  

            case ACTION_ID_CHANGE_PASSWORD:
                this.setState({ isChangePasswordModalOpen: true });
                break;  

            case ACTION_ID_LOGOUT:
                this.props.userManager.logout();
                break;

            case ACTION_ID_EXPORT_PDF:
                this.setState({ isExportPdfModalOpen: true });
                break;
        }
    }

    private onActionBarItemClick = (id: string) => {
        switch (id) {
            case ACTION_ID_GO_TO_DESKTOP_PORTAL:
                window.location.href = this.props.configuration.endpoints.formulariumPortalWeb;
                break;
        }
    }

    private navigateToPath = (path: string) =>
    {
        this.props.history.push(path);
        this.setSelectedDrawerItemId(path);
    }

	render()
	{
		return(
			<AbstractDrawer
                variant="persistent"
                anchor="left"
                open={this.state.isDrawerMenuOpen}
                drawerWidth={DRAWER_WIDTH}
                onClose={() => this.setIsDrawerMenuOpen(false)}
                model={this.getDrawerBuilder().build()}
                onItemClick={this.onDrawerItemClick}
                selectedItemId={this.state.selectedDrawerItemId}>

                <AbstractActionBar 
                    position="relative"
                    title="Formularium UKP"
                    model={this.getActionBarBuilder().build()}
                    mobileBreakpoint="sm"/>

                <Route path="/" exact component={ContentMedicine} />
                <Route path={PATH_MEDICINES} exact component={ContentMedicine} />
                <Route path={PATH_MEDICINE_EDIT} exact component={ContentMedicineEdit} />
                <Route path={PATH_ACTIVE_SUBSTANCES} exact component={ContentActiveSubstances} />
                <Route path={PATH_METRICS} exact component={ContentMetrics} />
                <Route path={PATH_CHANNELS} exact component={ContentChannels} />
                <Route path={PATH_FEI_REGISTRATIONS} exact component={ContentFeiRegistrations} />
                <Route path={PATH_ADMINISTRATION} exact component={ContentAdministrations} />
                <Route path={PATH_MEDICINE_TYPES} exact component={ContentMedicineTypes} />
                <Route path={PATH_TAGS} exact component={ContentTags} />
                <Route path={PATH_CATEGORIES} exact component={ContentCategories} />
                <Route path={PATH_COUPONS} exact component={ContentCoupons} />
                <Route path={PATH_PROTOCOLS} exact component={ContentProtocols} />
                <Route path={PATH_DOCUMENTS} exact component={ContentDocuments} />

                <ModalChangePassword open={this.state.isChangePasswordModalOpen} onClose={() => this.setState({ isChangePasswordModalOpen: false })} />
                <ModalExportWord open={this.state.isExportPdfModalOpen} onClose={() => this.setState({ isExportPdfModalOpen: false })} />
            </AbstractDrawer>
		);
	}
}

const hoc1 = withRouter(PageHome);
const hoc2 = connect(mapStateToProps)(hoc1);

export default hoc2;