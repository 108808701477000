import AbstractReduxAction from "./AbstractReduxAction";
import {User} from "@silvester/utility-auth";

export default class OnUserInitialized extends AbstractReduxAction
{
    public static readonly TYPE = "on_user_initialized";

    public user: User | null;

    constructor(user: User | null)
    {
        super(OnUserInitialized.TYPE);

        this.user = user;
    }
}