import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional, AbstractTableBuilder, AbstractTable, AbstractTable_Model} from "@silvester/react-web-tables";
import FormEditChannel from "../forms/FormEditChannel";
import { AbstractForm_Data, withFormApi, WithFormApi_Props_Injected } from "@silvester/react-web-forms";

const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_NAME = "name";
const FIELD_DESCRIPTION = "description";

const FORM_API_PATH = "/entities/channel/table";
const TABLE_API_PATH = "/entities/channel/table";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

interface OwnProps 
{
}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableChannels_Row> &
    WithFormApi_Props_Injected;

export interface TableChannels_Row
{
    [FIELD_ID]: number,
    [FIELD_NAME]: string,
    [FIELD_DESCRIPTION]: string
}

const TableChannels = (props: Props) =>
{
    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Kanalisaties");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_NAME, "Naam", 2)
            .addColumn(FIELD_DESCRIPTION, "Omschrijving", 9)
            .addAction("edit");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_NAME])
                .addTextual(row[FIELD_DESCRIPTION])
                .addActionEdit(props.getActionEdit(row, "Kanaal Aanpassen", { type: FormEditChannel, props: { user: props.user, formApi: props.getFormApi(FORM_API_PATH), id: row[FIELD_ID].toString() } }));
        });

        

        return builder;
    }

   

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}

const hoc1 = asManagedTable<TableChannels_Row, Props>(TableChannels, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);

export default hoc3;