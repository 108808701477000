import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable, asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional} from "@silvester/react-web-tables";
import { TableRowActionEdit_Props_External, ChildForm_Edit } from "@silvester/react-web-tables/dist/parts/actions/TableRowActionEdit";
import { Omit } from "@material-ui/core";
import { AbstractForm_Data, FormValueChangedCallback, RemoteSelectApi, withFormApi, WithFormApi_Props_Injected } from "@silvester/react-web-forms";
import FormEditMedicineSubstance from "../forms/FormEditMedicineSubstance";
import FormAddMedicineSubstance, { FormAddMedicineSubstance_Selects } from "../forms/FormAddMedicineSubstance";
import ReduxConfigurationEntity, { ReduxConfigurationEndpoints } from "../../../../../redux/entities/ReduxConfigurationEntity";
import { ChildSpecification } from "@silvester/utility-child-specification";
import { User } from "@silvester/utility-auth";
import ReduxState from "../../../../../redux/ReduxState";
import { connect } from "react-redux";


const mapStateToProps = (state: ReduxState) => ({
    user: state.user,
    endpoints: state.configuration!.endpoints
});


const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_ACTIVE_SUBSTANCE = "activeSubstance";
const FIELD_ACTIVE_SUBSTANCE_AMOUNT = "activeSubstanceAmount";
const FIELD_ACTIVE_SUBSTANCE_METRIC = "activeSubstanceMetric";
const FIELD_VOLUME_AMOUNT = "volumeAmount";
const FIELD_VOLUME_METRIC = "volumeMetric";

const FORM_API_PATH = "/entities/medicine_substance/table";
const TABLE_API_PATH = "/entities/medicine_substance/table";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

type OwnProps =
{
    endpoints: ReduxConfigurationEndpoints,
    user: User | undefined
}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableMedicineSubstance_Row> &
    WithFormApi_Props_Injected;

export interface TableMedicineSubstance_Row
{
    [FIELD_ID]: number,
    [FIELD_ACTIVE_SUBSTANCE]: string,
    [FIELD_ACTIVE_SUBSTANCE_AMOUNT]: number,
    [FIELD_ACTIVE_SUBSTANCE_METRIC]: string,
    [FIELD_VOLUME_AMOUNT]: number,
    [FIELD_VOLUME_METRIC]: string,
}

const TableMedicinePackages = (props: Props) =>
{
    const selectApi = new Map<keyof FormAddMedicineSubstance_Selects, RemoteSelectApi>();
    selectApi.set("volumeMetricId", new RemoteSelectApi(props.endpoints.formulariumAdminApi, "/entities/metric/select"));
    selectApi.set("activeSubstanceMetricId", new RemoteSelectApi(props.endpoints.formulariumAdminApi, "/entities/metric/select"));
    selectApi.set("activeSubstanceId", new RemoteSelectApi(props.endpoints.formulariumAdminApi, "/entities/active_substance/select"));

    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Stoffen");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.addActionAdd(props.getActionAdd("Stof Toevoegen", "Toevoegen", { type: FormAddMedicineSubstance, props: { medicineId: props.parentEntityId!, selectApi: selectApi, user: props.user } }))
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_ACTIVE_SUBSTANCE, "Stof", 3)
            .addColumn(FIELD_ACTIVE_SUBSTANCE_AMOUNT, "Hoeveelheid", 2)
            .addColumn(FIELD_ACTIVE_SUBSTANCE_METRIC, "Metriek", 2)
            .addColumn(FIELD_VOLUME_AMOUNT, "Per", 2)
            .addColumn(FIELD_VOLUME_METRIC, "Per Metriek", 2)
            .addAction("edit")
            .addAction("delete");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_ACTIVE_SUBSTANCE])
                .addNumerical(row[FIELD_ACTIVE_SUBSTANCE_AMOUNT], 4)
                .addTextual(row[FIELD_ACTIVE_SUBSTANCE_METRIC])
                .addNumerical(row[FIELD_VOLUME_AMOUNT], 4)
                .addTextual(row[FIELD_VOLUME_METRIC])
                .addActionEdit(props.getActionEdit(row, "Stof Aanpassen", { type: FormEditMedicineSubstance, props: { medicineId: props.parentEntityId!, user: props.user, id: row[FIELD_ID].toString(), formApi: props.getFormApi(FORM_API_PATH), selectApi: selectApi } }))
                .addActionDelete(props.getActionDelete("Verwijderen", "Weet u zeker dat u deze stof van dit medicijn wilt verwijderen? Dit kan niet ongedaan worden gemaakt!"));
        });
        

        return builder;
    }

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}

const hoc1 = asManagedTable<TableMedicineSubstance_Row, Props>(TableMedicinePackages, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);
const hoc4 = connect(mapStateToProps)(hoc3);

export default hoc4;