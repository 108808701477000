import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, WithInitialData_Props_Injected, withInitialData, RemoteSelectInformation, withManagedSelects, WithManagedSelects_Props_Injected, WithManagedSelects_Props_Additional } from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";
import { compose } from "redux";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})


const FIELD_ID = "id";
const FIELD_BRAND = "brand";
const FIELD_ATC_VET_CODE = "atcVetCode";
const FIELD_CHANNEL_ID = "channelId";
const FIELD_MEDICINE_TYPE_ID = "medicineTypeId";
const FIELD_WAITING_TIME = "waitingTime";
const FIELD_PRESERVABILITY = "preservability";
const FIELD_REGISTRATION = "registration";
const FIELD_PRICE_INDICATION = "priceIndication";
const FIELD_DESCRIPTION = "description";
const FIELD_PREPARATION = "preparation";
const FIELD_WARNING = "warning";
const FIELD_CALCULATE = "calculate";
const FIELD_LITERATURE = "literature";

interface Data
{
    [FIELD_ID]: string,
    [FIELD_BRAND]: string,
    [FIELD_ATC_VET_CODE]: string,
    [FIELD_ATC_VET_CODE]: string,
    [FIELD_CHANNEL_ID]: string,
    [FIELD_MEDICINE_TYPE_ID]: string,
    [FIELD_WAITING_TIME]: string,
    [FIELD_PRESERVABILITY]: string,
    [FIELD_REGISTRATION]: string,
    [FIELD_PRICE_INDICATION]: string,
    [FIELD_DESCRIPTION]: string,
    [FIELD_PREPARATION]: string,
    [FIELD_WARNING]: string,
    [FIELD_CALCULATE]: boolean,
    [FIELD_LITERATURE]: string
}
interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
}

export interface FormEditMedicine_Selects
{
    [FIELD_CHANNEL_ID]: RemoteSelectInformation,
    [FIELD_MEDICINE_TYPE_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormEditMedicine_Selects> &
    WithInitialData_Props_Injected<Data>;

interface State
{
}

class FormEditMedicine extends React.Component<Props, State>
{
    private getFormBuilder = (data: Partial<Data>) =>
    {
        const SIZE_FULL: GridSize = { sm: 12 };
        const SIZE_HALF: GridSize = { xs: 12, sm: 6 };

        const builder = new AbstractFormBuilder();
        builder.setIsLoading(this.props.isLoading);
        builder
            .addHidden({ id: FIELD_ID, input: data[FIELD_ID]})
            .addGroup()
            .addRow()
            .addText(SIZE_HALF, { id: FIELD_BRAND, input: data[FIELD_BRAND], label: "Merk", validator: new AbstractValidatorBuilder().required().minimum(2).maximum(32).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addText(SIZE_HALF, { id: FIELD_ATC_VET_CODE, input: data[FIELD_ATC_VET_CODE], label: "ATC Vet Code", validator: new AbstractValidatorBuilder().required().minimum(8).maximum(8).legal("ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789").build() })
            .addRow()
            .addSelect(SIZE_HALF, { id: FIELD_CHANNEL_ID, input: data[FIELD_CHANNEL_ID], label: "Kanalisatie", options: this.props.getSelectOptions(FIELD_CHANNEL_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })
            .addSelect(SIZE_HALF, { id: FIELD_MEDICINE_TYPE_ID, input: data[FIELD_MEDICINE_TYPE_ID], label: "Geneesmiddelvorm", options: this.props.getSelectOptions(FIELD_MEDICINE_TYPE_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })
            .addRow()
            .addText(SIZE_HALF, { id: FIELD_WAITING_TIME, input: data[FIELD_WAITING_TIME], label: "Wachttijd - Vlees", validator: new AbstractValidatorBuilder().maximum(50).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addText(SIZE_HALF, { id: FIELD_PRESERVABILITY, input: data[FIELD_PRESERVABILITY], label: "Houdbaarheid na openen/aanmaken", validator: new AbstractValidatorBuilder().maximum(64).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_HALF, { id: FIELD_REGISTRATION, input: data[FIELD_REGISTRATION], label: "Registratie", validator: new AbstractValidatorBuilder().maximum(64).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addText(SIZE_HALF, { id: FIELD_PRICE_INDICATION, input: data[FIELD_PRICE_INDICATION], label: "Prijsindicatie", validator: new AbstractValidatorBuilder().maximum(128).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_DESCRIPTION, input: data[FIELD_DESCRIPTION], multiline: true, numberOfLines: 5, label: "Toelichting", validator: new AbstractValidatorBuilder().required().legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_PREPARATION, input: data[FIELD_PREPARATION],  multiline: true, numberOfLines: 5, label: "Bereiding", validator: new AbstractValidatorBuilder().maximum(500).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_WARNING, input: data[FIELD_WARNING], multiline: true, numberOfLines: 5, label: "Waarschuwing", validator: new AbstractValidatorBuilder().legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addCheckbox(SIZE_FULL, { id: FIELD_CALCULATE, input: data[FIELD_CALCULATE], label: "Berekeningen Actief" })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_LITERATURE, input: data[FIELD_LITERATURE], label: "(Read Only) Oude Waarde Literatuur", readOnly: true, multiline: true, numberOfLines: 5 })
        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder(this.props.initialData || {}).build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormEditMedicine);
const hoc2 = withManagedSelects<FormEditMedicine_Selects, Props>(hoc1);
const hoc3 = withInitialData<Data, OwnProps & WithInitialData_Props_Injected<Data> & WithManagedSelects_Props_Additional<FormEditMedicine_Selects>>(hoc2);

export default hoc3;