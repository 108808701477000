import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { WithInitialData_Props_Injected, withInitialData, AbstractForm, AbstractFormBuilder, FormValueChangedCallback, FormInputSelect_Option} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";


const FIELD_ID = "id";
const FIELD_NAME = "name";
const FIELD_PRIORITY = "priority";

const styles = (theme: Theme) => 
({
    root:
    {

    }
    })

interface Data
{

    [FIELD_ID]: number,
    [FIELD_NAME]: string,
    [FIELD_PRIORITY]: number
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
}

type Props = OwnProps & WithInitialData_Props_Injected<Data>;

interface State
{
    
}

class FormEditFeiRegistration extends React.Component<Props, State>
{
    private getFormBuilder = () =>
    {
        const data = this.props.initialData || {}
        const SIZE_FULL: GridSize = {sm: 12};

        const builder = new AbstractFormBuilder();
        builder.setIsLoading(this.props.isLoading);
        builder
            .addHidden({ id: FIELD_ID, input: data[FIELD_ID] })
            .addHidden({ id: FIELD_PRIORITY, input: data[FIELD_PRIORITY] })
            .addGroup()
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_NAME, label: "Naam", input: data[FIELD_NAME], validator: new AbstractValidatorBuilder().required().minimum(2).maximum(24).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, OwnProps>(styles)(FormEditFeiRegistration);
const hoc2 = withInitialData<Data, OwnProps & WithInitialData_Props_Injected<Data>>(hoc1);

export default hoc2;
