import Oidc from 'oidc-client';
import AbstractReduxAction from './AbstractReduxAction';
import { UserManager } from '@silvester/utility-auth';
export default class OnAuthInitialized extends AbstractReduxAction
{
    public static readonly TYPE = "on_auth_initialized";

    public userManager: UserManager;

    constructor(userManager: UserManager)
    {
        super(OnAuthInitialized.TYPE);

        this.userManager = userManager;
    }
}