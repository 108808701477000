export default class FileDownloader
{
    private readonly blob: Blob;
    constructor(blob: Blob)
    {
        this.blob = blob;
    }

    public download = (filename: string) =>
    {
        const url = this.createObjectUrl();
        const link = this.createLinkElement(url, filename);

        this.appendToDocument(link);
        link.click();
        this.removeFromDocument(link);
    }

    private createObjectUrl = () =>
    {
        return window.URL.createObjectURL(this.blob);
    }

    private createLinkElement = (url: string, filename: string) =>
    {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute("download", filename);

        return link;
    }

    private appendToDocument = (element: HTMLElement) =>
    {
        document.body.append(element);
    }

    private removeFromDocument = (element: HTMLElement) =>
    {
        const parent = element.parentNode;
        if (parent)
        {
            parent.removeChild(element);
        }
    }
}