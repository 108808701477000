import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable, asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional} from "@silvester/react-web-tables";
import { TableRowActionEdit_Props_External } from "@silvester/react-web-tables/dist/parts/actions/TableRowActionEdit";
import { Omit } from "@material-ui/core";
import { WithFormApi_Props_Injected, withFormApi } from "@silvester/react-web-forms";
import UploadDocumentForm from "./UploadDocumentForm";
import { HttpContentType } from "@silvester/utility-auth";

const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_NAME = "name";
const FIELD_URL= "url";

const FORM_API_PATH = "/documents/table";
const TABLE_API_PATH = "/documents/table";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

interface OwnProps
{

}

type Props = OwnProps &
    AsManagedTable_Props_Injected<UploadDocumentTable_Row> &
    WithFormApi_Props_Injected;

export interface UploadDocumentTable_Row
{
    [FIELD_ID]: number,
    [FIELD_NAME]: string,
    [FIELD_URL]: string
}

const UploadDocumentTable = (props: Props) =>
{
    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Documenten");
        builder.addActionAdd(props.getActionAdd("Document Toevoegen", "Toevoegen", { type: UploadDocumentForm, props: {} }))
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_NAME, "Naam", 3)
            .addColumn(FIELD_URL, "Url", 8)
            .addAction("delete");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_NAME])
                .addTextual(row[FIELD_URL])
                .addActionDelete(props.getActionDelete("Verwijder Document", "Weet u zeker dat u deze categorie wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt. Tevens kan het zo zijn dat u middels een Protocol naar dit document verwijst. Deze link zal dan niet meer werken!"));
        });

        return builder;
    }

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}

const hoc1 = asManagedTable<UploadDocumentTable_Row, Props>(UploadDocumentTable, TABLE_API_PATH, { contentType: HttpContentType.MULTIPART_FORM_DATA } );
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);

export default hoc3;