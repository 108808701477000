import { AbstractApi, HttpContentType } from "@silvester/utility-auth";
import { ReduxConfigurationEndpoints } from "../../../redux/entities/ReduxConfigurationEntity";
import { AbstractForm_Data } from "@silvester/react-web-forms";

export default class ChangePasswordApi extends AbstractApi
{
    constructor(endpoints: ReduxConfigurationEndpoints)
    {
        super(endpoints.formulariumIdentity);
    }

    public sendChangePasswordRequest = async (formData: AbstractForm_Data) =>
    {
        const options = {
            contentType: HttpContentType.APPLICATION_JSON
        }
        return await this.sendPostRequest("/account/changePassword", formData, undefined, options);
    }
}