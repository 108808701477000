import { createMuiTheme } from '@material-ui/core/styles';

export const DefaultTheme = createMuiTheme
    ({
        palette:
            {
                secondary:
                    {
                        light: "#B0BEC5",
                        main: "#B0BEC5",
                        dark: "#455A64",
                        contrastText: '#000000'
                    },
                primary:
                    {
                        light: "#D1C4E9",
                        main: "#673AB7",
                        dark: "#512DA8",
                        contrastText: '#FFFFFF'
                    }
            },
        overrides:
            {
                MuiButton:
                    {
                        label:
                            {
                                paddingTop: '0px'
                            }
                    }
            }

});

