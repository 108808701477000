import React from "react";
import { ModalForm, AbstractForm_Data, ChildForm, WithCallback, RemoteSelectApi} from "@silvester/react-web-forms";
import { AbstractApi, User } from "@silvester/utility-auth";
import ReduxState from "../../../redux/ReduxState";
import { connect } from "react-redux";
import { ReduxConfigurationEndpoints } from "../../../redux/entities/ReduxConfigurationEntity";
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ModalMessage, ModalConfirmation } from "@silvester/react-web-modals";
import ExportWordApi from "./ExportWordApi";
import ExportWordForm, { ExportWordForm_Selects } from "./ExportWordForm";
import FileDownloader from "../../../utilities/files/FileDownloader";

const mapStateToProps = (state: ReduxState) =>
({
        endpoints: state.configuration!.endpoints,
        user: state.user!
})

interface Props
{
    user: User,
    open: boolean,
    onClose: () => void,
    endpoints: ReduxConfigurationEndpoints
}

interface State
{

}

class ModalExportWord extends React.Component<Props, State>
{
    
    private onSubmit = async (data: AbstractForm_Data) =>
    {
        const api = new ExportWordApi(this.props.endpoints);
        const response = await api.sendExportRequest(this.props.user, data);
        if (response.status >= 200 && response.status < 300)
        {
            const downloader = new FileDownloader(await response.blob());
            downloader.download("formularium_ukp_export.docx");

            this.props.onClose();
            return true;
        }
        else
        {
            return false;
        } 
    }

    private getForm = (): ChildForm<WithCallback> =>
    {
        const selectApi = new Map<keyof ExportWordForm_Selects, RemoteSelectApi>();
        selectApi.set("orderId", new RemoteSelectApi(this.props.endpoints.formulariumAdminApi, "/exports/form/selects/order"));
        const result: ChildForm<any> = { type: ExportWordForm, props: { selectApi: selectApi, user: this.props.user } };
        return result;
    }

    render()
    {
        return (
            <div>
                <ModalForm cancelable={true} title="Word Document Genereren" open={this.props.open} cancelText="Annuleren" submitText="Genereren" onClose={this.props.onClose} onSubmit={this.onSubmit} form={this.getForm()} />
            </div>
        );
    }
}

const hoc1 = connect(mapStateToProps)(ModalExportWord);
export default hoc1;