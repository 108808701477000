import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable } from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, WithManagedSelects_Props_Injected, withManagedSelects, RemoteSelectInformation } from "@silvester/react-web-forms";
import { AbstractValidatorBuilder } from "@silvester/utility-validation";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) =>
({
    root:
    {

    }
})

const FIELD_ORDER_ID = "orderId";

interface Data
{
    [FIELD_ORDER_ID]: string,
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
}

export interface ExportWordForm_Selects
{
    [FIELD_ORDER_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<ExportWordForm_Selects>;

interface State
{

}

class ExportWordForm extends React.Component<Props, State>
{
    private getFormBuilder = () =>
    {
        const builder = new AbstractFormBuilder();
        builder
            .addGroup()
            .addRow()
            .addSelect({ xs: 12 }, { id: FIELD_ORDER_ID, label: "Sorteren Op", options: this.props.getSelectOptions(FIELD_ORDER_ID).options, validator: new AbstractValidatorBuilder().required().legal("active_substancebrand").build() });

        return builder;
    }

    render() {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(ExportWordForm);
const hoc2 = withManagedSelects<ExportWordForm_Selects, OwnProps & WithManagedSelects_Props_Injected<ExportWordForm_Selects>>(hoc1);

export default hoc2; 