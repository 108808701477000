import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import {AbstractForm, AbstractFormBuilder, FormValueChangedCallback, FormInputSelect_Option} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_FILE = "file";

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
}

type Props = OwnProps;

interface State
{
    
}

class UploadDocumentForm extends React.Component<Props, State>
{
    private getFormBuilder = () =>
    {
        const SIZE_FULL: GridSize = {sm: 12};

        const builder = new AbstractFormBuilder();
        builder
            .addGroup()
            .addRow()
            .addFile(SIZE_FULL, { id: FIELD_FILE, label: "Document", validator: new AbstractValidatorBuilder().required().build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(UploadDocumentForm);

export default hoc1; 