import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, RemoteSelectInformation, withManagedSelects, WithManagedSelects_Props_Injected} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_AMOUNT = "amount";
const FIELD_METRIC_ID = "metricId";
const FIELD_MEDICINE_ID = "medicineId";

interface Data
{
    [FIELD_AMOUNT]: number,
    [FIELD_METRIC_ID]: number,
    [FIELD_MEDICINE_ID]: number
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    medicineId: string
}

export interface FormAddMedicinePackage_Selects
{
    [FIELD_METRIC_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormAddMedicinePackage_Selects>;

interface State
{
    
}

class FormAddMedicinePackage extends React.Component<Props, State>
{
   
    private getFormBuilder = () =>
    {
        const builder = new AbstractFormBuilder();
        builder
            .addHidden({ id: FIELD_MEDICINE_ID, input: this.props.medicineId})
            .addGroup()
            .addRow()
            .addNumerical({xs: 12, sm: 9}, { id: FIELD_AMOUNT, label: "Hoeveelheid", validator: new AbstractValidatorBuilder().required().build() })
            .addSelect({ xs: 12, sm: 3 }, { id: FIELD_METRIC_ID, label: "Metriek", options: this.props.getSelectOptions(FIELD_METRIC_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormAddMedicinePackage);
const hoc2 = withManagedSelects<FormAddMedicinePackage_Selects, OwnProps & WithManagedSelects_Props_Injected<FormAddMedicinePackage_Selects>>(hoc1);

export default hoc2; 