import React from "react";
import { ModalForm, AbstractForm_Data } from "@silvester/react-web-forms";
import FormChangePassword from "./FormChangePassword";
import { AbstractApi } from "@silvester/utility-auth";
import ChangePasswordApi from "./ChangePasswordApi";
import ReduxState from "../../../redux/ReduxState";
import { connect } from "react-redux";
import { ReduxConfigurationEndpoints } from "../../../redux/entities/ReduxConfigurationEntity";
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ModalMessage } from "@silvester/react-web-modals";

const mapStateToProps = (state: ReduxState) =>
({
    endpoints: state.configuration!.endpoints
})

interface Props
{
    open: boolean,
    onClose: () => void,
    endpoints: ReduxConfigurationEndpoints
}

interface State
{
    shouldShowSnackbar: boolean,
    snackbarMessage: string
}

class ModalChangePassword extends React.Component<Props, State>
{
    state: State =
    {
            shouldShowSnackbar: false,
            snackbarMessage: ""
    }

    private onSubmit = async (data: AbstractForm_Data) =>
    {
        try
        {
            const api = new ChangePasswordApi(this.props.endpoints);
            const response = await api.sendChangePasswordRequest(data);

            const json = await response.json();
            if (json.success)
            {
                this.showSnackbar("Uw wachtwoord is met succes bijgewerkt!");
                this.props.onClose();
                return true;
            }
            else
            {
                this.showSnackbar(json.errors[0]);
                return false;
            }
        }
        catch (e)
        {
            this.showSnackbar("Er ging iets mis bij het opslaan van het formulier. Probeer het a.u.b. opnieuw, of neem contact op met de beheerders van de website.");
            return false;
        }
    }

    private showSnackbar = (message: string) =>
    {
        this.setSnackbarMessage(message);
        this.setShouldShowSnackbar(true);
    }

    private setSnackbarMessage = (message: string) =>
    {
        this.setState({ snackbarMessage: message });
    }

    private setShouldShowSnackbar = (shouldShowSnackbar: boolean) =>
    {
        this.setState({ shouldShowSnackbar: shouldShowSnackbar });
    }

    render()
    {
        return (
            <div>
                <ModalForm cancelable={true} title="Wachtwoord Wijzigen" open={this.props.open} onClose={this.props.onClose} onSubmit={this.onSubmit} form={{ type: FormChangePassword, props: {} }} />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.shouldShowSnackbar}
                    autoHideDuration={15000}
                    onClose={() => this.setShouldShowSnackbar(false)}
                    message={<span>{this.state.snackbarMessage}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => this.setShouldShowSnackbar(false)}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}>
                </Snackbar>
            </div>
        );
    }
}

const hoc1 = connect(mapStateToProps)(ModalChangePassword);
export default hoc1;