import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, FormInputSelect_Option, withInitialData, WithInitialData_Props_Injected, RemoteSelectInformation, withManagedSelects, WithManagedSelects_Props_Injected, WithManagedSelects_Props_Additional } from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_ID = "id";
const FIELD_NAME = "name";
const FIELD_ADDENDUM = "addendum";
const FIELD_DESCRIPTION = "description";
const FIELD_FEI_REGISTRATION_DESCRIPTION = "feiRegistrationDescription";
const FIELD_FEI_REGISTRATION_ID = "feiRegistrationId";

interface Data
{
    [FIELD_ID]: number,
    [FIELD_NAME]: string,
    [FIELD_ADDENDUM]: string | null,
    [FIELD_DESCRIPTION]: string,
    [FIELD_FEI_REGISTRATION_DESCRIPTION]: string | null,
    [FIELD_FEI_REGISTRATION_ID]: number,
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    initialData?: Partial<Data>
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormEditActiveSubstance_Selects> &
    WithInitialData_Props_Injected<Data>;

export interface FormEditActiveSubstance_Selects
{
    [FIELD_FEI_REGISTRATION_ID]: RemoteSelectInformation
}


interface State
{
    
}

class FormEditActiveSubstance extends React.Component<Props, State>
{
   
    private getFormBuilder = (data: Partial<Data>) =>
    {
        const SIZE_FULL: GridSize = {sm: 12};

        const builder = new AbstractFormBuilder();
        builder.setIsLoading(this.props.isLoading);
        builder
            .addHidden({ id: FIELD_ID, input: data[FIELD_ID] })
            .addGroup()
            .addRow()
            .addText({ xs: 12, sm: 9 }, { id: FIELD_NAME, input: data[FIELD_NAME], label: "Naam", validator: new AbstractValidatorBuilder().required().minimum(2).maximum(32).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addText({ xs: 12, sm: 3 }, { id: FIELD_ADDENDUM, input: data[FIELD_ADDENDUM],label: "Addendum", validator: new AbstractValidatorBuilder().maximum(32).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_DESCRIPTION, input: data[FIELD_DESCRIPTION], label: "Omschrijving", multiline: true, numberOfLines: 5, validator: new AbstractValidatorBuilder().required().minimum(5).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addSelect(SIZE_FULL, { id: FIELD_FEI_REGISTRATION_ID, input: data[FIELD_FEI_REGISTRATION_ID],label: "FEI Registratie", options: this.props.getSelectOptions(FIELD_FEI_REGISTRATION_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_FEI_REGISTRATION_DESCRIPTION, input: data[FIELD_FEI_REGISTRATION_DESCRIPTION], label: "FEI Registratie Omschrijving", multiline: true, numberOfLines: 5, validator: new AbstractValidatorBuilder().maximum(1024).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder(this.props.initialData || {}).build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormEditActiveSubstance);
const hoc2 = withManagedSelects<FormEditActiveSubstance_Selects, Props>(hoc1);
const hoc3 = withInitialData<Data, OwnProps & WithInitialData_Props_Injected<Data> & WithManagedSelects_Props_Additional<FormEditActiveSubstance_Selects>>(hoc2);

export default hoc3;
