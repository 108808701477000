import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ReduxStoreDefault from './redux/stores/ReduxStoreDefault';
import { Provider } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { unregister } from './registerServiceWorker';
import OnAuthInitialized from './redux/actions/ActionAuthInitialized';
import OnConfigurationLoaded from './redux/actions/ActionConfigurationLoaded';
import { DefaultTheme } from './theme/DefaultTheme';
import ReduxConfigurationEntity, { ReduxConfigurationEndpoints } from './redux/entities/ReduxConfigurationEntity';
import { UserManager } from "@silvester/utility-auth";
import OnUserInitialized from './redux/actions/ActionUserInitialized';

//Declare the root component.
interface Props
{

}

interface State
{
    configuration?: ReduxConfigurationEntity,
}

console.log("Life Cycle: Application started.");
class Root extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state =
        {

        };
    }

    componentWillMount = () =>
    {
        //Load the hosting configuration from the public endpoint.
        fetch("javascript/configuration")
            .then(response =>
            {
                console.log("Life Cycle: Configuration retrieved.");
                return response.json();
            })
            .then(json =>
            {
                //Signal the Redux Store that the configuration has been loaded.
                ReduxStoreDefault.instance.store.dispatch(new OnConfigurationLoaded(json));

                //Create an Auth object and notify the Redux Store that the Auth object has been initialized.
                UserManager.initialize({
                    authority: json.endpoints.formulariumIdentity,
                    client_id: "formularium.admin.web",
                    redirect_uri: json.endpoints.formulariumAdminWeb + "/callback/login",
                    post_logout_redirect_uri: json.endpoints.formulariumAdminWeb +  "/",
                    response_type: "code",
                    scope: "openid profile formularium-admin-api",
                });
                const userManager = UserManager.getInstance()!;

                ReduxStoreDefault.instance.store.dispatch(new OnAuthInitialized(userManager));

                //Initialize the current user, if it exists, and then remove the loading state from the root component.
                userManager.getUser().then(user =>
                {
                    ReduxStoreDefault.instance.store.dispatch(new OnUserInitialized(user));

                    console.log("Life Cycle: Authorization initialized.");
                    this.setConfiguration(json);
                });
            });
    }

    isLoading = () =>
    {
        return this.state.configuration == null;
    }

    setConfiguration = (configuration: ReduxConfigurationEntity) =>
    {
        this.setState({configuration: configuration});
    }
    
    render()
    {
        if (this.isLoading())
        {
            return (
                <div>
                    <p>Loading application, please wait.</p>
                </div>
            );
        }
        else
        {
            return (
                <div>
                    <Provider store={ReduxStoreDefault.instance.store}>
                        <div>
                            <CssBaseline />
                            <MuiThemeProvider theme={DefaultTheme}>
                                <div id="root-content">
                                    <BrowserRouter basename={this.state.configuration!.base}>
                                        <App />
                                    </BrowserRouter>
                                </div>
                            </MuiThemeProvider>
                        </div>
                    </Provider>
                </div>
            );
        }
    }
}

//Insert the root component into the DOM.
const rootElement = document.getElementById('root');
var RootComponent: Root | null = null;

ReactDOM.render(
    <Root ref={i => RootComponent = i} />,
    rootElement);

unregister();
export default RootComponent;

