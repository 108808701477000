import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional, AbstractTableBuilder, AbstractTable, AbstractTable_Model} from "@silvester/react-web-tables";
import { AbstractForm_Data, FormValueChangedCallback, ChildForm, withFormApi, WithFormApi_Props_Injected, RemoteSelectApi } from "@silvester/react-web-forms";
import FormAddAndroidCoupon, { FormAddAndroidCoupon_Selects } from "../forms/FormAddAndroidCoupon";
import ReduxConfigurationEntity, { ReduxConfigurationEndpoints } from "../../../../../redux/entities/ReduxConfigurationEntity";
import { ChildSpecification } from "@silvester/utility-child-specification";
import { User } from "@silvester/utility-auth";
import ReduxState from "../../../../../redux/ReduxState";
import { connect } from "react-redux";


const mapStateToProps = (state: ReduxState) => ({
    user: state.user,
    endpoints: state.configuration!.endpoints
});


const PAGES_TO_SHOW = 5;

const FIELD_KEY = "key";
const FIELD_SUBSCRIPTION_ID = "subscriptionId";
const FIELD_VALID_THRU = "validThru";

const FORM_API_PATH = "/entities/coupons/table";
const TABLE_API_PATH = "/entities/coupons/table";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

type OwnProps =
{
    endpoints: ReduxConfigurationEndpoints,
    user: User | undefined
}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableAndroidCoupons_Row> &
    WithFormApi_Props_Injected;

export interface TableAndroidCoupons_Row
{
    [FIELD_KEY]: string,
    [FIELD_SUBSCRIPTION_ID]: string,
    [FIELD_VALID_THRU]: string
}

const TableAdministrations = (props: Props) =>
{
    const selectApi = new Map<keyof FormAddAndroidCoupon_Selects, RemoteSelectApi>();
    selectApi.set("subscriptionId", new RemoteSelectApi(props.endpoints.formulariumAdminApi, "/entities/subscription/select"));

    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Coupons");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.addActionAdd(props.getActionAdd("Coupon Toevoegen", "Toevoegen", { type: FormAddAndroidCoupon, props: { selectApi: selectApi, user: props.user } }))
        builder.getHeader()
            .addColumn(FIELD_KEY, "Key", 3)
            .addColumn(FIELD_SUBSCRIPTION_ID, "Subscription Id", 3)
            .addColumn(FIELD_VALID_THRU, "Valid Thru", 6)
            .addAction("delete");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_KEY].toString())
                .addTextual(row[FIELD_KEY])
                .addTextual(row[FIELD_SUBSCRIPTION_ID])
                .addTextual(row[FIELD_VALID_THRU])
                .addActionDelete(props.getActionDelete("Verwijderen", "Weet u zeker dat u deze promotiecode wilt verwijderen? Dit kan niet ongedaan worden gemaakt!"));
        });
        

        return builder;
    }

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}

const hoc1 = asManagedTable<TableAndroidCoupons_Row, Props>(TableAdministrations, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);
const hoc4 = connect(mapStateToProps)(hoc3);

export default hoc4;