import React from "react";
import {AbstractActionBarBuilder, AbstractActionBar} from "@silvester/react-web-action-bars";
import {AbstractListBuilder} from "@silvester/react-web-lists";
import {AbstractDrawerBuilder, AbstractDrawer} from "@silvester/react-web-drawers";
import { withRouter, Route, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { Theme } from "@material-ui/core";
import injectSheet from "react-jss";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { User } from "@silvester/utility-auth";
import queryString from "query-string";
import { CardForm, ChildForm, AbstractForm_Data, WithCallback, FormValueChangedCallback, RemoteSelectApi, RemoteFormApi } from "@silvester/react-web-forms";
import { RemoteTableApi } from "@silvester/react-web-tables";
import { ChildSpecification } from "@silvester/utility-child-specification";
import ReduxState from "../../../../../redux/ReduxState";
import ReduxConfigurationEntity from "../../../../../redux/entities/ReduxConfigurationEntity";
import { FormEditMedicine_Selects } from "../forms/FormEditMedicine";
import FormEditMedicine from "../forms/FormEditMedicine";
import { ModalMessage } from "@silvester/react-web-modals";

const mapStateToProps = (state: ReduxState) => 
({
        user: state.user,
        configuration: state.configuration!
});

const styles = (theme: Theme) => 
({
    root: 
    {   
        padding: 20 
    }
});

export interface CardMedicineEdit_Props_External 
{
    medicineId: string
}

interface CardMedicineEdit_Props_Internal
{
    classes: ClassNameMap,
    user?: User,
    configuration: ReduxConfigurationEntity
}

type Props = CardMedicineEdit_Props_External &
    CardMedicineEdit_Props_Internal;

interface State
{
    isMessageModalOpen: boolean,
    messageModal:
    {
        text: string,
        title: string
    }
}

class CardMedicineEdit extends React.Component<Props, State>
{
    private formApi: RemoteFormApi;
    private selectApi: Map<keyof FormEditMedicine_Selects, RemoteSelectApi>;

    constructor(props: Props)
    {
        super(props);

        this.formApi = new RemoteFormApi(this.props.configuration.endpoints.formulariumAdminApi, "/entities/medicine/table");

        this.selectApi = new Map<keyof FormEditMedicine_Selects, RemoteSelectApi>();
        this.selectApi.set("channelId", new RemoteSelectApi(props.configuration.endpoints.formulariumAdminApi, "/entities/channel/select"));
        this.selectApi.set("medicineTypeId", new RemoteSelectApi(props.configuration.endpoints.formulariumAdminApi, "/entities/medicine_type/select"));

        this.state =
        {
            isMessageModalOpen: false,
            messageModal:
            {
                text: "",
                title: ""
            }
        }
    }

    private getForm = (): ChildForm<any> =>
    {
        const specification: ChildForm<{ user: User | undefined, id: string, formApi: RemoteFormApi, selectApi: Map<keyof FormEditMedicine_Selects, RemoteSelectApi>, onFieldChange: FormValueChangedCallback }> =
        {
            type: FormEditMedicine,
            props: { user: this.props.user, id: this.props.medicineId, formApi: this.formApi, selectApi: this.selectApi }
        }

        return specification;
    }

    private onSubmitEditMedicine = async (data: AbstractForm_Data) =>
    {
        try
        {
            const api = new RemoteTableApi<AbstractForm_Data>(this.props.configuration.endpoints.formulariumAdminApi, "/entities/medicine/table", 1);
            await api.editTableRow(this.props.user, data);

            this.setMessageModalContent("Succesvol", "De aanpassingen aan het medicijn zijn met succes opgeslagen!");
            this.setIsMessageModalOpen(true);
        }
        catch (e)
        {
            this.setMessageModalContent("Onsuccesvol", "De aanpassingen aan het medicijn konden niet worden opgeslagen. Probeer het later opnieuw. Als dit probleem zich blijft voordoen, neem dan contact op met de ontwikkelaars van de applicatie.");
            this.setIsMessageModalOpen(true);
        }

        return false;
    }

    private setMessageModalContent = (title: string, text: string) =>
    {
        this.setState({ messageModal: { title: title, text: text } });
    }

    private setIsMessageModalOpen = (isMessageModalOpen: boolean) =>
    {
        this.setState({ isMessageModalOpen: isMessageModalOpen });
    }

	render()
	{
        const {classes} = this.props;

        return (
            <div>
                <CardForm submitText="Opslaan" title="Gegevens" form={this.getForm()} onSubmit={this.onSubmitEditMedicine} />
                <ModalMessage onConfirm={() => this.setIsMessageModalOpen(false)} title={this.state.messageModal.title} text={this.state.messageModal.text} open={this.state.isMessageModalOpen} />
            </div>
        );
	}
}

const hoc1 = connect(mapStateToProps)(CardMedicineEdit);
export default injectSheet<string, Theme, CardMedicineEdit_Props_External>(styles)(hoc1);
