import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, withManagedSelects, WithManagedSelects_Props_Injected, WithManagedSelects_Props_Additional, RemoteSelectInformation, WithInitialData_Props_Injected, withInitialData} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";
import { compose } from "redux";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_ID = "id";
const FIELD_NAME = "name";
const FIELD_CATEGORY_ID = "administrationCategoryId";

interface Data
{
    [FIELD_ID]: string,
    [FIELD_NAME]: string,
    [FIELD_CATEGORY_ID]: number
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
}

export interface FormEditAdministration_Selects
{
    [FIELD_CATEGORY_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormEditAdministration_Selects> &
    WithInitialData_Props_Injected<Data>;

interface State
{
}

class FormEditAdministration extends React.Component<Props, State>
{
    private getFormBuilder = (data: Partial<Data>) =>
    {
        const SIZE_FULL: GridSize = {sm: 12};

        const builder = new AbstractFormBuilder();
        builder.setIsLoading(this.props.isLoading);
        builder
            .addHidden({ id: FIELD_ID, input: data[FIELD_ID] })
            .addGroup()
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_NAME, label: "Naam", input: data[FIELD_NAME], validator: new AbstractValidatorBuilder().required().minimum(2).maximum(32).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addSelect(SIZE_FULL, { id: FIELD_CATEGORY_ID, label: "Categorie", input: data[FIELD_CATEGORY_ID], options: this.props.getSelectOptions(FIELD_CATEGORY_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder(this.props.initialData || {}).build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormEditAdministration);
const hoc2 = withManagedSelects<FormEditAdministration_Selects, Props>(hoc1);
const hoc3 = withInitialData<Data, OwnProps & WithInitialData_Props_Injected<Data> & WithManagedSelects_Props_Additional<FormEditAdministration_Selects>>(hoc2);

export default hoc3;