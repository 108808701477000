import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional, AbstractTableBuilder, AbstractTable, AbstractTable_Model} from "@silvester/react-web-tables";
import FormAddMedicineProtocol, { FormAddMedicineProtocol_Selects } from "../forms/FormAddMedicineProtocol";
import { User } from "@silvester/utility-auth";
import { RemoteSelectApi, withFormApi, WithFormApi_Props_Injected } from "@silvester/react-web-forms";
import { ReduxConfigurationEndpoints } from "../../../../../redux/entities/ReduxConfigurationEntity";
import ReduxState from "../../../../../redux/ReduxState";
import { connect } from "react-redux";


const mapStateToProps = (state: ReduxState) => ({
    user: state.user,
    endpoints: state.configuration!.endpoints
});


const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_TITLE = "title";
const FIELD_URL = "url";
const FIELD_DESCRIPTION = "description";

const FORM_API_PATH = "/entities/medicine_protocol/table";
const TABLE_API_PATH = "/entities/medicine_protocol/table";

const styles = (theme: Theme) => 
({
    root: 
    {

    }
})

type OwnProps =
{
    endpoints: ReduxConfigurationEndpoints,
    user: User | undefined
}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableMedicineProtocol_Row> &
    WithFormApi_Props_Injected;

export interface TableMedicineProtocol_Row
{
    [FIELD_ID]: number,
    [FIELD_TITLE]: string,
    [FIELD_URL]: string,
    [FIELD_DESCRIPTION]: string
}

const TableMedicineProtocols = (props: Props) =>
{
    const selectApi = new Map<keyof FormAddMedicineProtocol_Selects, RemoteSelectApi>();
    selectApi.set("protocolId", new RemoteSelectApi(props.endpoints.formulariumAdminApi, "/entities/protocol/select"));

    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Protocol");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.addActionAdd(props.getActionAdd("Protocol Toevoegen", "Toevoegen", { type: FormAddMedicineProtocol, props: { medicineId: props.parentEntityId!, selectApi: selectApi, user: props.user } }))
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_TITLE, "Naam", 2)
            .addColumn(FIELD_URL, "Naam", 3)
            .addColumn(FIELD_DESCRIPTION, "Naam", 6)
            .addAction("delete");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(props.emptyMessage);

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_TITLE])
                .addTextual(row[FIELD_URL])
                .addTextual(row[FIELD_DESCRIPTION])
                .addActionDelete(props.getActionDelete("Verwijderen", "Weet u zeker dat u dit protocol van dit medicijn wilt verwijderen? Dit kan niet ongedaan worden gemaakt!"));
        });
        

        return builder;
    }

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}

const hoc1 = asManagedTable<TableMedicineProtocol_Row, Props>(TableMedicineProtocols, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);
const hoc4 = connect(mapStateToProps)(hoc3);

export default hoc4;