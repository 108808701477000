import React from "react";
import {AbstractActionBarBuilder, AbstractActionBar} from "@silvester/react-web-action-bars";
import {AbstractListBuilder} from "@silvester/react-web-lists";
import {AbstractDrawerBuilder, AbstractDrawer} from "@silvester/react-web-drawers";
import { withRouter, Route } from "react-router-dom";
import { History } from "history";
import { Theme } from "@material-ui/core";
import injectSheet from "react-jss";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import ReduxState from "../../../../redux/ReduxState";
import ReduxConfigurationEntity from "../../../../redux/entities/ReduxConfigurationEntity";
import { User } from "@silvester/utility-auth";
import TableTags, { TableTags_Row } from "../../../components/entities/tags/tables/TableTags";

const mapStateToProps = (state: ReduxState) => 
({
        user: state.user,
        configuration: state.configuration!
});

const styles = (theme: Theme) => 
({
    root: 
    {   
        padding: 20 
    }
});

export interface ContentTags_Props_External 
{

}

interface ContentTags_Props_Internal
{
    classes: ClassNameMap,
    user?: User,
    configuration: ReduxConfigurationEntity
}

type Props = ContentTags_Props_External & ContentTags_Props_Internal;

interface State
{
  
}

class ContentTags extends React.Component<Props, State>
{
	render()
	{
        const {classes} = this.props;

		return(
            <div className={classes.root}>
                <TableTags user={this.props.user} endpoint={this.props.configuration.endpoints.formulariumAdminApi} rowsPerPage={15} />
            </div>
		);
	}
}

const hoc1 = connect(mapStateToProps)(ContentTags);
export default injectSheet<string, Theme, ContentTags_Props_External>(styles)(hoc1);
