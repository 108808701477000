import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, withManagedSelects, WithManagedSelects_Props_Injected, RemoteSelectInformation} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_SUBSCRIPTION_ID = "subscriptionId";
const FIELD_VALID_THRU = "validThru";

interface Data
{
    [FIELD_SUBSCRIPTION_ID]: number,
    [FIELD_VALID_THRU]: string
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
}

export interface FormAddAndroidCoupon_Selects
{
    [FIELD_SUBSCRIPTION_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormAddAndroidCoupon_Selects>;

interface State
{
    
}

class FormAddAndroidCoupon extends React.Component<Props, State>
{
   
    private getFormBuilder = () =>
    {
        const SIZE_FULL: GridSize = {sm: 12};

        const builder = new AbstractFormBuilder();
        builder
            .addGroup()
            .addRow()
            .addSelect(SIZE_FULL, { id: FIELD_SUBSCRIPTION_ID, label: "Subscription Id", options: this.props.getSelectOptions(FIELD_SUBSCRIPTION_ID).options, validator: new AbstractValidatorBuilder().required().legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_VALID_THRU, label: "Valid Thru (2018-01-01 00:00:00.000)", placeholder:"2018-01-01 00:00:00.000", validator: new AbstractValidatorBuilder().required().minimum(2).maximum(32).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormAddAndroidCoupon);
const hoc2 = withManagedSelects<FormAddAndroidCoupon_Selects, OwnProps & WithManagedSelects_Props_Injected<FormAddAndroidCoupon_Selects>>(hoc1);

export default hoc2; 