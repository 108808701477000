import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import {AbstractForm, AbstractFormBuilder, FormValueChangedCallback, FormInputSelect_Option} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";
import { compose } from "redux";
import ReduxState from "../../../redux/ReduxState";
import { User, UserManager } from "@silvester/utility-auth";
import { connect } from "react-redux";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../values/strings";

const mapStateToProps = (state: ReduxState) =>
    ({
        userManager: state.userManager!,
        user: state.user!
})

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_EMAIL = "email";
const FIELD_CURRENT_PASSWORD = "currentPassword";
const FIELD_NEW_PASSWORD = "newPassword";
const FIELD_NEW_PASSWORD_CONFIRM = "newPasswordConfirm";

interface Data
{
    [FIELD_EMAIL]: string,
    [FIELD_CURRENT_PASSWORD]: string,
    [FIELD_NEW_PASSWORD]: string,
    [FIELD_NEW_PASSWORD_CONFIRM]: string,

}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    user: User,
    userManager: UserManager
}

type Props = OwnProps;

interface State
{
}

class FormChangePassword extends React.Component<Props, State>
{
    private newPassword?: string;

    private getFormBuilder = () =>
    {
        const SIZE_FULL: GridSize = { xs: 12 };
        const SIZE_HALF: GridSize = { xs: 12, sm: 6 };

        const builder = new AbstractFormBuilder();
        builder
            .addHidden({ id: FIELD_EMAIL, input: this.props.user.getOidcUser()!.profile.email })
            .addGroup()
            .addRow()
            .addPassword(SIZE_FULL, { id: FIELD_CURRENT_PASSWORD, label: "Huidig Wachtwoord", validator: new AbstractValidatorBuilder().required().minimum(8).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addPassword(SIZE_HALF, { id: FIELD_NEW_PASSWORD, label: "Nieuw Wachtwoord", validator: new AbstractValidatorBuilder().required().minimum(8).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addPassword(SIZE_HALF, { id: FIELD_NEW_PASSWORD_CONFIRM, label: "Herhaal Nieuw Wachtwoord", validator: new AbstractValidatorBuilder().required().confirmation(() => this.newPassword).minimum(8).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
         
        return builder;
    }

    private onFieldChange: FormValueChangedCallback = (id: string, value: any, error: string | undefined) =>
    {
        this.props.onFieldChange(id, value, error);
        if (id == FIELD_NEW_PASSWORD)
        {
            this.newPassword = value;
        }
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormChangePassword);
const hoc2 = connect(mapStateToProps)(hoc1);

export default hoc2;