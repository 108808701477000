import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable, asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional} from "@silvester/react-web-tables";
import { TableRowActionEdit_Props_External, ChildForm_Edit } from "@silvester/react-web-tables/dist/parts/actions/TableRowActionEdit";
import { Omit } from "@material-ui/core";
import { AbstractForm_Data, FormValueChangedCallback, RemoteSelectApi, WithFormApi_Props_Injected, withFormApi } from "@silvester/react-web-forms";
import FormEditMedicineIndicationDosageCalculation from "../forms/FormEditMedicineIndicationDosageCalculation";
import FormAddMedicineIndicationDosageCalculation, { FormAddMedicineIndicationDosageCalculation_Selects} from "../forms/FormAddMedicineIndicationDosageCalculation";
import ReduxConfigurationEntity, { ReduxConfigurationEndpoints } from "../../../../../redux/entities/ReduxConfigurationEntity";
import { User } from "@silvester/utility-auth";
import ReduxState from "../../../../../redux/ReduxState";
import { connect } from "react-redux";


const mapStateToProps = (state: ReduxState) => ({
    user: state.user,
    endpoints: state.configuration!.endpoints
});


const PAGES_TO_SHOW = 5;


const FIELD_ID = "id";
const FIELD_INITIAL_DOSAGE = "initialDosage";
const FIELD_DOSAGE_RANGE_LOWER = "dosageRangeLower";
const FIELD_DOSAGE_RANGE_UPPER = "dosageRangeUpper";
const FIELD_WEIGHT_RANGE_LOWER = "weightRangeLower";
const FIELD_WEIGHT_RANGE_UPPER = "weightRangeUpper";
const FIELD_PER_METRIC = "perMetric";
const FIELD_DOSAGE_METRIC = "dosageMetric";
const FIELD_PER_AMOUNT = "perAmount"


const FORM_API_PATH = "/entities/medicine_indication_dosage_calculation/table";
const TABLE_API_PATH = "/entities/medicine_indication_dosage_calculation/table";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

type OwnProps =
{
    endpoints: ReduxConfigurationEndpoints,
    user: User | undefined,
}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableMedicineIndicationDosageCalculation_Row> &
    WithFormApi_Props_Injected;

export interface TableMedicineIndicationDosageCalculation_Row
{
    [FIELD_ID]: number,
    [FIELD_INITIAL_DOSAGE]: number,
    [FIELD_DOSAGE_RANGE_LOWER]: number,
    [FIELD_DOSAGE_RANGE_UPPER]: number,
    [FIELD_DOSAGE_METRIC]: string,
    [FIELD_PER_AMOUNT]: number,
    [FIELD_PER_METRIC]: string,
    [FIELD_WEIGHT_RANGE_LOWER]: number,
    [FIELD_WEIGHT_RANGE_UPPER]: number,
}

const TableMedicineIndication = (props: Props) =>
{
    const selectApi = new Map<keyof FormAddMedicineIndicationDosageCalculation_Selects, RemoteSelectApi>();
    selectApi.set("dosageMetricId", new RemoteSelectApi(props.endpoints.formulariumAdminApi, "/entities/metric/select"));

    const getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(props.rowsPerPage, "Doseringsberekeningen");
        builder.addPagination(props.currentPage, props.amountOfPages, PAGES_TO_SHOW, props.onPageSelected);
        builder.addActionAdd(props.getActionAdd("Berekening Toevoegen", "Toevoegen", { type: FormAddMedicineIndicationDosageCalculation, props: { user: props.user, indicationId: props.parentEntityId!, selectApi: selectApi } }, { disabled: shouldAddBeDisabled() }));
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_INITIAL_DOSAGE, "Initiële", 2)
            .addColumn(FIELD_DOSAGE_RANGE_LOWER, "Laagste", 1)
            .addColumn(FIELD_DOSAGE_RANGE_UPPER, "Hoogste", 1)
            .addColumn(FIELD_DOSAGE_METRIC, "Metriek", 2)
            .addColumn(FIELD_PER_AMOUNT, "Per", 1)
            .addColumn(FIELD_PER_METRIC, "Per Metriek", 2)
            .addColumn(FIELD_WEIGHT_RANGE_LOWER, "Laagste Gewicht", 1)
            .addColumn(FIELD_WEIGHT_RANGE_UPPER, "Hoogste Gewicht", 1)
            .addAction("edit")
            .addAction("delete");

        builder.getBody()
            .setIsLoading(props.isLoading)
            .setEmptyMessage(getEmptyMessage());

        props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .addNumerical(row[FIELD_ID], 0)
                .addNumerical(row[FIELD_INITIAL_DOSAGE], 4)
                .addNumerical(row[FIELD_DOSAGE_RANGE_LOWER], 4)
                .addNumerical(row[FIELD_DOSAGE_RANGE_UPPER], 4)
                .addTextual(row[FIELD_DOSAGE_METRIC])
                .addNumerical(row[FIELD_PER_AMOUNT], 0)
                .addTextual(row[FIELD_PER_METRIC])
                .addNumerical(row[FIELD_WEIGHT_RANGE_LOWER], 0)
                .addNumerical(row[FIELD_WEIGHT_RANGE_UPPER], 0)
                .addActionEdit(props.getActionEdit(row, "Doseringsberekening Aanpassen", { type: FormEditMedicineIndicationDosageCalculation, props: { indicationId: props.parentEntityId!, selectApi: selectApi, user: props.user, id: row[FIELD_ID].toString(), formApi: props.getFormApi(FORM_API_PATH) } }))
                .addActionDelete(props.getActionDelete("Verwijderen", "Weet u zeker dat u deze berekening van deze indicatie wilt verwijderen? Dit kan niet ongedaan worden gemaakt!"));
        });
        
        return builder;
    }

    const shouldAddBeDisabled = () =>
    {
        return props.parentEntityId == undefined
    }

    const getEmptyMessage = () =>
    {
        if (props.parentEntityId == undefined)
        {
            return "Selecteer een indicatie in de tabel hierboven om er berekeningen aan toe te kunnen voegen.";
        }
        else
        {
            return "Er zijn nog geen berekeningen toegevoegd aan de indicatie met Id " + props.parentEntityId + ".";
        }
    }

    return (
        <AbstractTable model={getTableBuilder().build()} />
    );
}

const hoc1 = asManagedTable<TableMedicineIndicationDosageCalculation_Row, Props>(TableMedicineIndication, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);
const hoc4 = connect(mapStateToProps)(hoc3);

export default hoc4;