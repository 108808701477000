import ReduxConfigurationEntity from "./entities/ReduxConfigurationEntity";
import { UserManager, User } from "@silvester/utility-auth";

export default class ReduxState
{
    configuration: ReduxConfigurationEntity | undefined;
    userManager: UserManager | undefined;
    user: User | undefined;

    constructor()
    {

    }
}
