import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import {AbstractTableBuilder, AbstractTable} from "@silvester/react-web-tables";
import {AbstractForm, AbstractFormBuilder, FormValueChangedCallback, FormInputSelect_Option} from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_MEDICINE_ID = "medicineId";
const FIELD_TITLE = "title";
const FIELD_DOSAGE_DESCRIPTION = "dosageDescription";
const FIELD_DESCRIPTION = "description";

interface Data
{
    [FIELD_MEDICINE_ID]: string,
    [FIELD_TITLE]: string,
    [FIELD_DOSAGE_DESCRIPTION]: string,
    [FIELD_DESCRIPTION]: string,
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    medicineId: string
}

type Props = OwnProps;

interface State
{
    
}

class FormAddMedicineIndication extends React.Component<Props, State>
{
    private getFormBuilder = () =>
    {
        const SIZE_FULL: GridSize = { xs: 12 };

        const builder = new AbstractFormBuilder();
        builder
            .addHidden({ id: FIELD_MEDICINE_ID, input: this.props.medicineId })
            .addGroup()
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_TITLE, label: "Titel", validator: new AbstractValidatorBuilder().required().minimum(2).maximum(64).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_DOSAGE_DESCRIPTION, label: "Dosering", multiline: true, numberOfLines: 5, validator: new AbstractValidatorBuilder().maximum(256).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() })
            .addRow()
            .addText(SIZE_FULL, { id: FIELD_DESCRIPTION, label: "Omschrijving", multiline: true, numberOfLines: 5, validator: new AbstractValidatorBuilder().required().minimum(5).maximum(1024).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).build() });

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormAddMedicineIndication);

export default hoc1; 