import React from "react";
import {AbstractActionBarBuilder, AbstractActionBar} from "@silvester/react-web-action-bars";
import {AbstractListBuilder} from "@silvester/react-web-lists";
import {AbstractDrawerBuilder, AbstractDrawer} from "@silvester/react-web-drawers";
import { withRouter, Route, RouteComponentProps } from "react-router-dom";
import { History } from "history";
import { Theme } from "@material-ui/core";
import injectSheet from "react-jss";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import ReduxState from "../../../../redux/ReduxState";
import ReduxConfigurationEntity from "../../../../redux/entities/ReduxConfigurationEntity";
import { User } from "@silvester/utility-auth";
import TableMetrics, { TableMetrics_Row } from "../../../components/entities/metrics/tables/TableMetrics";
import queryString from "query-string";
import { CardForm, ChildForm, AbstractForm_Data, WithCallback, RemoteSelectApi } from "@silvester/react-web-forms";
import FormEditMedicine, { FormEditMedicine_Selects } from "../../../components/entities/medicines/forms/FormEditMedicine";
import { ChildForm_Edit_Props } from "@silvester/react-web-tables";
import { ChildSpecification } from "@silvester/utility-child-specification";
import CardMedicineEdit from "../../../components/entities/medicines/cards/CardMedicineEdit";
import TabsMedicineEdit from "../../../components/entities/medicines/tabs/TabsMedicineEdit";

const mapStateToProps = (state: ReduxState) => 
({
        user: state.user,
        configuration: state.configuration!
});

const styles = (theme: Theme) => 
({
    root: 
    {   
        padding: 20 
    }
});

export interface ContentMedicineEdit_Props_External 
{

}

interface ContentMedicineEdit_Props_Internal
{
    classes: ClassNameMap,
    user?: User,
    configuration: ReduxConfigurationEntity
}

type Props = ContentMedicineEdit_Props_External &
    ContentMedicineEdit_Props_Internal &
    RouteComponentProps;

interface State
{
  medicineId: string
}

class ContentMedicine extends React.Component<Props, State>
{
    private selectApi: Map<keyof FormEditMedicine_Selects, RemoteSelectApi>;

    constructor(props: Props)
    {
        super(props);

        this.selectApi = new Map<keyof FormEditMedicine_Selects, RemoteSelectApi>();
        this.selectApi.set("channelId", new RemoteSelectApi(props.configuration.endpoints.formulariumAdminApi, "/entities/channel/select"));
        this.selectApi.set("medicineTypeId", new RemoteSelectApi(props.configuration.endpoints.formulariumAdminApi, "/entities/medicine_type/select"));

        this.state =
        {
            medicineId: queryString.parse(this.props.location.search).id as string
        }
    }

	render()
	{
        const {classes} = this.props;

		return(
            <div className={classes.root}>
                <CardMedicineEdit medicineId={this.state.medicineId} />
                <div style={{paddingTop: 20}}></div>
                <TabsMedicineEdit medicineId={this.state.medicineId} />
            </div>
		);
	}
}

const hoc1 = connect(mapStateToProps)(ContentMedicine);
export default injectSheet<string, Theme, ContentMedicineEdit_Props_External>(styles)(hoc1);
