import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { asManagedTable, AsManagedTable_Props_Injected, AsManagedTable_Props_Additional, AbstractTableBuilder, AbstractTable, AbstractTable_Model} from "@silvester/react-web-tables";
import { TableRowActionEdit_Props_External, ChildForm_Edit } from "@silvester/react-web-tables/dist/parts/actions/TableRowActionEdit";
import { Omit } from "@material-ui/core";
import { AbstractForm_Data, FormValueChangedCallback, RemoteFormApi, withFormApi, WithFormApi_Props_Injected  } from "@silvester/react-web-forms";
import FormEditMedicineIndication from "../forms/FormEditMedicineIndication";
import FormAddMedicineIndication from "../forms/FormAddMedicineIndication";
import ReduxConfigurationEntity, { ReduxConfigurationEndpoints } from "../../../../../redux/entities/ReduxConfigurationEntity";
import { ChildSpecification } from "@silvester/utility-child-specification";
import { User } from "@silvester/utility-auth";
import { WithCallback } from "@silvester/react-web-forms/dist/components/forms/instances/WithForm";
import ReduxState from "../../../../../redux/ReduxState";
import { connect } from "react-redux";

const mapStateToProps = (state: ReduxState) => ({
    user: state.user,
    endpoints: state.configuration!.endpoints
});

const PAGES_TO_SHOW = 5;

const FIELD_ID = "id";
const FIELD_TITLE = "title";
const FIELD_DOSAGE_DESCRIPTION = "dosageDescription";
const FIELD_DESCRIPTION = "description";

const FORM_API_PATH = "/entities/medicine_indication/table";
const TABLE_API_PATH = "/entities/medicine_indication/table";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

type OwnProps =
{
    endpoints: ReduxConfigurationEndpoints,
    user: User | undefined,
    onIndicationSelected: (rowId: string) => void
}

type Props = OwnProps &
    AsManagedTable_Props_Injected<TableMedicineIndication_Row> &
    WithFormApi_Props_Injected;

export interface TableMedicineIndication_Row
{
    [FIELD_ID]: number,
    [FIELD_TITLE]: string,
    [FIELD_DOSAGE_DESCRIPTION]: string,
    [FIELD_DESCRIPTION]: string,
}

interface State
{
    selectedRowId?: string
}

class TableMedicineIndication extends React.Component<Props, State>
{
    state: State =
    {

    }

    private getTableBuilder = () =>
    {
        const builder = new AbstractTableBuilder(this.props.rowsPerPage, "Indicaties");
        builder.addPagination(this.props.currentPage, this.props.amountOfPages, PAGES_TO_SHOW, this.props.onPageSelected);
        builder.addActionAdd(this.props.getActionAdd("Indicatie Toevoegen", "Toevoegen", { type: FormAddMedicineIndication, props: { medicineId: this.props.parentEntityId!} }))
        builder.getHeader()
            .addColumn(FIELD_ID, "Id", 1)
            .addColumn(FIELD_TITLE, "Titel", 2)
            .addColumn(FIELD_DOSAGE_DESCRIPTION, "Dosering", 2)
            .addColumn(FIELD_DESCRIPTION, "Omschrijving", 7)
            .addAction("edit")
            .addAction("delete");

        builder.getBody()
            .setIsLoading(this.props.isLoading)
            .setEmptyMessage(this.props.emptyMessage);

        this.props.rows.forEach(row => {
            builder.getBody()
                .addRow(row[FIELD_ID].toString())
                .setIsSelected(row[FIELD_ID].toString() == this.state.selectedRowId)
                .setOnRowClickHandler(this.onRowClick)
                .addNumerical(row[FIELD_ID], 0)
                .addTextual(row[FIELD_TITLE])
                .addTextual(row[FIELD_DOSAGE_DESCRIPTION])
                .addTextual(row[FIELD_DESCRIPTION])
                .addActionEdit(this.props.getActionEdit(row, "Indicatie Aanpassen", { type: FormEditMedicineIndication, props: { medicineId: this.props.parentEntityId!, user: this.props.user, id: row[FIELD_ID].toString(), formApi: this.props.getFormApi(FORM_API_PATH)} }))
                .addActionDelete(this.props.getActionDelete("Verwijderen", "Weet u zeker dat u deze indicatie van dit medicijn wilt verwijderen? Dit kan niet ongedaan worden gemaakt!"));
        });
        
        return builder;
    }
    
    private onRowClick = (rowId: string) =>
    {
        this.setState({ selectedRowId: rowId });
        this.props.onIndicationSelected(rowId);
    }

    render()
    {
        return (
            <AbstractTable model={this.getTableBuilder().build()} />
        );
    }
}

const hoc1 = asManagedTable<TableMedicineIndication_Row, Props>(TableMedicineIndication, TABLE_API_PATH);
const hoc2 = withFormApi<OwnProps & WithFormApi_Props_Injected & AsManagedTable_Props_Additional>(hoc1);
const hoc3 = injectSheet<string, Theme, OwnProps>(styles)(hoc2);
const hoc4 = connect(mapStateToProps)(hoc3);

export default hoc4;