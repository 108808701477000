import React, { Component } from 'react';
import { Route, Router} from 'react-router';
import PageHome from './ui/pages/home/PageHome';
import { AuthorizedComponent, User, UserManager } from '@silvester/utility-auth';
import ReduxState from './redux/ReduxState';
import { connect } from 'react-redux';

const mapStateToProps = (state: ReduxState) =>
({
    user: state.user!,
    auth: state.userManager!
})

interface Props
{
    user: User,
    auth: UserManager
}

class App extends React.Component<Props>
{
    displayName = App.name

    render() 
    {
        console.log(this.props.user);
        if (this.props.user.isLoggedIn() == false || this.props.user.hasOneOfTheseRoles(["developer", "product_owner"])) {
            return (
                <AuthorizedComponent user={this.props.user} userManager={this.props.auth} allowedRoles={["developer", "product_owner"]} automaticallySendToLogin={this.props.user.isLoggedIn() == false}>
                    <PageHome />
                </AuthorizedComponent>
            );
        }
        else {
            return (
                <div>
                    <p>You do not have the roles required to use this application. You need to be a developer or a product_owner.</p>
                    <button onClick={() => this.props.auth.logout()}>Logout and change account</button>
                </div>
            )
        }
    }    
}

export default connect(mapStateToProps)(App);
  