import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, withManagedSelects, WithManagedSelects_Props_Injected, RemoteSelectInformation } from "@silvester/react-web-forms";
import {AbstractValidatorBuilder} from "@silvester/utility-validation";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";

const styles = (theme: Theme) => 
({
    root:
    {

    }
})

const FIELD_ACTIVE_SUBSTANCE_ID = "activeSubstanceId";
const FIELD_ACTIVE_SUBSTANCE_AMOUNT = "activeSubstanceAmount";
const FIELD_ACTIVE_SUBSTANCE_METRIC_ID = "activeSubstanceMetricId";
const FIELD_VOLUME_AMOUNT = "volumeAmount";
const FIELD_VOLUME_METRIC_ID = "volumeMetricId";
const FIELD_MEDICINE_ID = "medicineId";

interface Data
{
    [FIELD_ACTIVE_SUBSTANCE_ID]: number,
    [FIELD_ACTIVE_SUBSTANCE_AMOUNT]: number,
    [FIELD_ACTIVE_SUBSTANCE_METRIC_ID]: number,
    [FIELD_MEDICINE_ID]: number,
    [FIELD_VOLUME_AMOUNT]: number,
    [FIELD_VOLUME_METRIC_ID]: number
}

interface OwnProps
{
    onFieldChange: FormValueChangedCallback,
    medicineId: string
}

export interface FormAddMedicineSubstance_Selects
{
    [FIELD_ACTIVE_SUBSTANCE_ID]: RemoteSelectInformation,
    [FIELD_ACTIVE_SUBSTANCE_METRIC_ID]: RemoteSelectInformation,
    [FIELD_VOLUME_METRIC_ID]: RemoteSelectInformation
}

type Props = OwnProps &
    WithManagedSelects_Props_Injected<FormAddMedicineSubstance_Selects>;

interface State
{
    
}

class FormAddMedicineSubstance extends React.Component<Props, State>
{
    private getFormBuilder = () =>
    {
        const SIZE_ONE_LINE: GridSize = { xs: 12, sm: 3 };

        const builder = new AbstractFormBuilder();
        builder
            .addHidden({ id: FIELD_MEDICINE_ID, input: this.props.medicineId })
            .addGroup()
            .addRow()
            .addSelect({ xs: 12 }, { id: FIELD_ACTIVE_SUBSTANCE_ID, label: "Actieve Stof", options: this.props.getSelectOptions(FIELD_ACTIVE_SUBSTANCE_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })
            .addRow()
            .addNumerical(SIZE_ONE_LINE, { id: FIELD_ACTIVE_SUBSTANCE_AMOUNT, label: "Hoeveelheid", decimals: 4, validator: new AbstractValidatorBuilder().required().build() })
            .addSelect(SIZE_ONE_LINE, { id: FIELD_ACTIVE_SUBSTANCE_METRIC_ID, label: "Metriek", options: this.props.getSelectOptions(FIELD_ACTIVE_SUBSTANCE_METRIC_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })
            .addNumerical(SIZE_ONE_LINE, { id: FIELD_VOLUME_AMOUNT, label: "Per Hoeveelheid", decimals: 4, validator: new AbstractValidatorBuilder().required().build() })
            .addSelect(SIZE_ONE_LINE, { id: FIELD_VOLUME_METRIC_ID, label: "Per Metriek", options: this.props.getSelectOptions(FIELD_VOLUME_METRIC_ID).options, validator: new AbstractValidatorBuilder().required().legal("0123456789").build() })

        return builder;
    }

    render()
    {
        return (
            <AbstractForm model={this.getFormBuilder().build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormAddMedicineSubstance);
const hoc2 = withManagedSelects<FormAddMedicineSubstance_Selects, OwnProps & WithManagedSelects_Props_Injected<FormAddMedicineSubstance_Selects>>(hoc1);

export default hoc2; 