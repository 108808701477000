import React from "react";
import { Theme } from "theming";
import injectSheet from "react-jss";
import { AbstractTableBuilder, AbstractTable } from "@silvester/react-web-tables";
import { AbstractForm, AbstractFormBuilder, FormValueChangedCallback, WithInitialData_Props_Injected, withInitialData } from "@silvester/react-web-forms";
import { AbstractValidatorBuilder } from "@silvester/utility-validation";
import { DEFAULT_LEGAL_INPUT_CHARACTERS } from "../../../../../values/strings";
import { GridSize } from "@silvester/react-web-forms/dist/components/enums/Grid";
import { compose } from "redux";

const styles = (theme: Theme) =>
    ({
        root:
        {

        }
    })

const FIELD_ID = "id";
const FIELD_TITLE = "title";
const FIELD_URL = "url";
const FIELD_DESCRIPTION = "description";
const FIELD_MEDICINE_ID = "medicineId";

interface Data {
    [FIELD_ID]: number,
    [FIELD_TITLE]: string,
    [FIELD_URL]: string,
    [FIELD_DESCRIPTION]: string,
    [FIELD_MEDICINE_ID]: number
}

interface OwnProps {
    onFieldChange: FormValueChangedCallback,
    medicineId: string
}

type Props = OwnProps & WithInitialData_Props_Injected<Data>;

interface State {
}

class FormEditMedicineLink extends React.Component<Props, State>
{
    private getFormBuilder = (data: Partial<Data>) => {
        const builder = new AbstractFormBuilder();
        builder.setIsLoading(this.props.isLoading);
        builder
            .addHidden({ id: FIELD_MEDICINE_ID, input: this.props.medicineId })
            .addHidden({ id: FIELD_ID, input: data[FIELD_ID] })
            .addGroup()
            .addRow()
            .addText({ xs: 12 }, { id: FIELD_TITLE, input: data[FIELD_TITLE], label: "Titel", validator: new AbstractValidatorBuilder().maximum(256).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).required().build() })
            .addText({ xs: 12 }, { id: FIELD_DESCRIPTION, input: data[FIELD_DESCRIPTION], label: "Omschrijving", validator: new AbstractValidatorBuilder().maximum(256).legal(DEFAULT_LEGAL_INPUT_CHARACTERS).required().build() })
            .addText({ xs: 12 }, { id: FIELD_URL, input: data[FIELD_URL], label: "Url", validator: new AbstractValidatorBuilder().url().legal(DEFAULT_LEGAL_INPUT_CHARACTERS).maximum(256).required().build() })

        return builder;
    }

    render() {
        return (
            <AbstractForm model={this.getFormBuilder(this.props.initialData || {}).build()} onFieldChange={this.props.onFieldChange} />
        );
    }
}

const hoc1 = injectSheet<string, Theme, Props>(styles)(FormEditMedicineLink);
const hoc2 = withInitialData<Data, OwnProps & WithInitialData_Props_Injected<Data>>(hoc1);

export default hoc2;